import { useNavigation } from '@react-navigation/core'
import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, TextInput } from 'react-native'
import { useAppContext } from "../contexts/AppContext"
import StandartHeading from '../components/StandartHeading'
import { CommonActions } from '@react-navigation/native';
import StandartButton from '../components/StandartButton';
import ButtonBottomWrapper from '../components/ButtonBottomWrapper';
import { Button, Box } from 'native-base'

const AddSupplierWaitScreen = () => {
  const navigation = useNavigation()
  const { addSupplier, updateSupplierCustomerId } = useAppContext()
  const [supplierName, setSupplierName] = useState(false);

  function continueWithCustomerId(supplier) {
    //var newCustomerId = customerIdRef.current.value
    //console.log(newCustomerId)
    updateSupplierCustomerId(supplier, 111)
    //history.push("/products")
  }

  // useEffect(() => {
  //   navigation.dispatch(
  //     CommonActions.reset({
  //       index: 1,
  //       routes: [
  //         { name: 'AddSupplierWait' }
  //       ],
  //     })
  //   );
  // }, [])

  return (
    <View style={styles.container}>
      <StandartHeading>Der Lieferant wird hinzugefügt</StandartHeading>
      <Box w={["80%", "80%", "600px"]} justifyContent="center">
        <Text style={{ textAlign: "center", lineHeight: 30 }}>

          Du erhälst eine
          Benachrichtigung wenn
          deine Bestellliste verarbeitet wurde. {"\n"}

          Das dauert in der Regel
          20 Minuten.{"\n"}

          Falls du vorher Fragen hast, rufe gerne unseren Support an.

        </Text>
        {supplierName && <Text>{supplierName}</Text>}
      </Box>
      <ButtonBottomWrapper>
        <StandartButton action={() => navigation.navigate("SupplierSelection")} title={"Fertig"}></StandartButton>
      </ButtonBottomWrapper>
    </View>
  )
}

export default AddSupplierWaitScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
})
