import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Entypo } from '@expo/vector-icons';

const DrawerMenuIcon = ({ navigation }) => {
    return (
        <TouchableOpacity onPress={() => navigation.openDrawer()}>
            <Entypo name="menu" size={25} color="black" style={{ paddingLeft: 15 }} />
        </TouchableOpacity>
    )
}

export default DrawerMenuIcon