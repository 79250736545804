import React from 'react'
import { View, Text } from 'react-native'
import { Stack, Button, Center, Flex, Box, VStack, Spacer } from 'native-base'
import { useNavigation } from '@react-navigation/core'
import StandartHeading from '../components/StandartHeading'
import StandartButton from "../components/StandartButton"


const IntroScreen = () => {
    const navigation = useNavigation()

    return (
        <Center style={{ height: "100%" }}>
            <StandartHeading>
                {"Los gehts! \n Melde dich an!"}
            </StandartHeading>
            <VStack justifyContent="flex-end" style={{ height: "100%" }}>

                <Flex direction={['column', 'column', 'row']} mb={['50px', '50px', '300px']}>
                    <Box m="20px">
                        <StandartButton action={() => navigation.navigate("SignUpPhone")} title={"Log In"} />

                    </Box>
                    <Box m="20px">
                        <StandartButton action={() => navigation.navigate("SignUpPhone")} title={"Registrieren"} />

                    </Box>
                    {/* <Button onPress={() => navigation.navigate("AddSupplier")}>

                    Weiter

                </Button> */}
                </Flex >
            </VStack>

        </Center >
    )
}

export default IntroScreen
