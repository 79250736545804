import moment from "moment";
import { Dimensions } from "react-native";

export const nestedObject = function (base, names, value) {
    // If a value is given, remove the last name and keep it for later:
    var lastName = arguments.length === 3 ? names.pop() : false;

    // Walk the hierarchy, creating new objects where needed.
    // If the lastName was removed, then the last object is not set yet:
    for (var i = 0; i < names.length; i++) {
        base = base[names[i]] = base[names[i]] || {};
    }

    // If a value was given, set it to the last name:
    if (lastName) base = base[lastName] = value;

    // Return the last object in the hierarchy:
    return base;
};

export const standartDate = function (timestamp) {
    return moment.unix(timestamp).format("DD.MM.YYYY HH:mm")
}

export const timestamp = function () {
    return Math.floor(Date.now() / 1000)
}

export const unitDict = function (unitKey) {
    let thisUnitDict = { "box": "Kiste", "single": "Stück" }
    return thisUnitDict[unitKey] ? thisUnitDict[unitKey] : unitKey
}

export const screenHeight = Dimensions.get('window').height
const navBarHeight = 65
export const usefullHeight = screenHeight - navBarHeight