import React from 'react'
import { View, Text, TextInput } from 'react-native'
import { GooglePlacesAutocomplete } from '../react-native-google-places-autocomplete-web';
import { FontAwesome } from '@expo/vector-icons';
import { Input } from 'native-base';
import { Entypo } from '@expo/vector-icons';

const RestaurantAutoComplete = (props) => {

    return (
        <GooglePlacesAutocomplete
            placeholder='Mein Restaurant'
            renderRightButton={() => <View style={{ zIndex: 999, position: "absolute", right: 10, height: "100%", justifyContent: "center" }}><FontAwesome name="search" size={18} color="#a6a6a6" /></View>}
            onPress={(data, details = null) => {
                // 'details' is provided when fetchDetails = true
                console.log(data, details);
                //placeClicked(details)
                //test()
                //setStreet("12312313123")
                props.onPlaceClicked(details)
            }}
            query={{
                key: 'AIzaSyAlt-Bghxa1t5kkLLr-F20xgKemiDAAVe0',
                language: 'de',
            }}
            requestUrl={{
                useOnPlatform: 'web', // or "all"
                url: 'https://gearground.com/orderlisto/proxy.php?csurl=https://maps.googleapis.com/maps/api'
                //url: 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api',
                //url: 'https://gearground.com/coars.php/?url=https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
                // headers: {
                //     Authorization: `an auth token`, // if required for your proxy
                // },
            }}
            fetchDetails={true}
            GooglePlacesDetailsQuery={{ fields: 'address_components,name' }}
            styles={{ textInputContainer: { height: 40 }, textInput: { borderWidth: 1, borderColor: "gray", width: "100%", position: "absolute", height: "100%" } }}

        />
    )
}

export default RestaurantAutoComplete
