import React from 'react'
import { NavigationContainer } from '@react-navigation/native';
import {
    createDrawerNavigator,
    DrawerContentScrollView,
    DrawerItemList,
    DrawerItem,
} from '@react-navigation/drawer';
import { navigationRef } from './RootNavigation';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from './screens/LoginScreen';
import IntroScreen from './screens/IntroScreen';
import LoginPhone from './screens/LoginPhone';
import SignUpScreen from './screens/SignUpScreen';
import SignUpPhone from './screens/SignUpPhone';
import HomeScreen from './screens/HomeScreen';
import ProductSelectionScreen from './screens/ProductSelectionScreen';
import SupplierSelectionScreen from './screens/SupplierSelectionScreen';
import AddSupplierScreen from './screens/AddSupplierScreen';
import AddSupplierDetailsScreen from './screens/AddSupplierDetailsScreen';
import CheckOrderScreen from './screens/CheckOrderScreen';
import OrderSuccessScreen from './screens/OrderSuccessScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import OrderHistoryDetailsScreen from './screens/OrderHistoryDetailsScreen';
import AddSupplierUploadScreen from './screens/AddSupplierUploadScreen';
import AddRestaurantScreen from './screens/AddRestaurantScreen';
import EditProductScreen from './screens/EditProductScreen';
import AddSupplierWaitScreen from './screens/AddSupplierWaitScreen';
import SupplierOrderConfirmationScreen from './screens/SupplierOrderConfirmationScreen';
import PhoneCodeScreen from './screens/PhoneCodeScreen';
import SettingsScreen from './screens/SettingsScreen';
import { View, Button, Text } from 'native-base'
import { useAppContext } from './contexts/AppContext';

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();


const linking = {
    config: {
        screens: {
            IntroScreen: "intro",
            SupplierSelection: "selectsupplier",
            Login: "login",
            SignUpPhone: "signup",
            PhoneCode: "phone-verification",
            SupplierOrderConfirmation: {
                path: 'order-confirmation/:orderDocId/',
                parse: {
                    orderDocId: (orderDocId) => orderDocId,
                },
                stringify: {
                    orderDocId: (orderDocId) => orderDocId,
                },
            },
            MainStack: {
                screens: {
                    ProductSelection: "products",
                    EditProduct: "edit-product",
                    AddRestaurant: "add-restaurant",
                    AddSupplier: "addsupplier",
                    AddSupplierDetails: "addsupplier-details",
                    AddSupplierUpload: "list-upload",
                    CheckOrder: "check-order",
                    OrderSuccess: "order-success",
                }
            },
            OrderHistoryStack: {
                screens: {
                    OrderHistory: "order-history",
                    OrderHistoryDetail: {
                        path: 'order-detail/:id/',
                        parse: {
                            id: (id) => id,
                        },
                        stringify: {
                            id: (id) => id,
                        },
                    },
                }
            },
            SettingsStack: {
                screens: {
                    Settings: "settings"
                }
            }

        }
    }

}

function Feed({ navigation }) {
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text>Feed Screen</Text>
            <Button title="Open drawer" onPress={() => navigation.openDrawer()} />
            <Button title="Toggle drawer" onPress={() => navigation.toggleDrawer()} />
        </View>
    );
}

function Notifications() {
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text>Notifications Screen</Text>
        </View>
    );
}

function CustomDrawerContent(props) {
    return (
        <DrawerContentScrollView {...props}>
            <DrawerItemList {...props} />
            {/* <DrawerItem
                label="Close drawer"
                onPress={() => props.navigation.closeDrawer()}
            />
            <DrawerItem
                label="Toggle drawer"
                onPress={() => props.navigation.toggleDrawer()}
            /> */}
        </DrawerContentScrollView>
    );
}

function UnAuthorizedStack() {
    return (
        <Stack.Navigator
            headerStyle={{
                shadowColor: 'transparent'
            }}
        // screenOptions={{
        //     headerTitle: "",
        //     headerHideShadow: true,
        //     headerTransparent: true,
        //     headerShadowVisible: false,
        //     //: { elevation: 0, shadowColor: 'transparent', headerShadowVisible: false }
        // }}
        >

            <Stack.Screen name="IntroScreen" component={IntroScreen} options={{ headerShown: false }} />
            {/* <Stack.Screen name="Login" component={LoginScreen} /> */}
            <Stack.Screen name="LoginPhone" component={LoginPhone} />
            <Stack.Screen name="SignUpPhone" component={SignUpPhone} options={{ headerShown: false }} />
            {/* <Stack.Screen name="SignUp" component={SignUpScreen} /> */}
            <Stack.Screen name="PhoneCode" component={PhoneCodeScreen} options={{ headerTitle: "", headerHideShadow: true, headerTransparent: true, headerShadowVisible: false }} />
            <Stack.Screen name="SupplierOrderConfirmation" component={SupplierOrderConfirmationScreen} options={{ headerTitle: "", headerHideShadow: true, headerTransparent: true, headerShadowVisible: false }} />

        </Stack.Navigator>
    )
}

function MainStack() {
    return (
        <Stack.Navigator
            headerStyle={{
                shadowColor: 'transparent'
            }}

        // screenOptions={{
        //     headerTitle: "",
        //     headerHideShadow: true,
        //     headerTransparent: true,
        //     headerShadowVisible: false,
        //     //: { elevation: 0, shadowColor: 'transparent', headerShadowVisible: false }
        // }}
        >
            <Stack.Screen name="SupplierSelection" component={SupplierSelectionScreen} options={{ headerTitle: "Lieferanten" }} />
            <Stack.Screen name="ProductSelection" component={ProductSelectionScreen} />
            <Stack.Screen name="EditProduct" component={EditProductScreen} options={{ headerTitle: "Produkt hinzufügen" }} />
            <Stack.Screen name="CheckOrder" component={CheckOrderScreen} options={{ headerTitle: "Bestellung prüfen" }} />
            <Stack.Screen name="OrderSuccess" component={OrderSuccessScreen} options={{ headerTitle: "Bestellung erfolgreich versendet" }} />
            <Stack.Screen name="AddRestaurant" component={AddRestaurantScreen} options={{ headerTitle: "Restaurant hinzufügen" }} />
            <Stack.Screen name="AddSupplier" component={AddSupplierScreen} options={{ headerTitle: "Lieferant hinzufügen" }} />
            <Stack.Screen name="AddSupplierDetails" component={AddSupplierDetailsScreen} options={{ headerTitle: "Lieferant hinzufügen" }} />
            <Stack.Screen name="AddSupplierUpload" component={AddSupplierUploadScreen} options={{ headerTitle: "Datei Upload" }} />
            <Stack.Screen name="AddSupplierWait" component={AddSupplierWaitScreen} options={{ headerShown: false }} />
        </Stack.Navigator>
    )
}

function OrderHistoryStack() {
    return (
        <Stack.Navigator
            headerStyle={{
                shadowColor: 'transparent'
            }}

        // screenOptions={{
        //     headerTitle: "",
        //     headerHideShadow: true,
        //     headerTransparent: true,
        //     headerShadowVisible: false,
        //     //: { elevation: 0, shadowColor: 'transparent', headerShadowVisible: false }
        // }}
        >
            <Stack.Screen name="OrderHistory" component={OrderHistoryScreen} options={{ headerTitle: "Bestellungen" }} />
            <Stack.Screen name="OrderHistoryDetail" component={OrderHistoryDetailsScreen} options={{ headerTitle: "Bestelldetails" }} />

        </Stack.Navigator>
    )
}

function SettingsStack() {
    return (<Stack.Navigator
        headerStyle={{
            shadowColor: 'transparent'
        }}>
        <Stack.Screen name="Settings" component={SettingsScreen} options={{ headerTitle: "Einstellungen" }} />

    </Stack.Navigator>)
}

function MyDrawer() {
    return (
        <Drawer.Navigator
            drawerContent={(props) => <CustomDrawerContent {...props} />}
            screenOptions={{
                headerShown: false,
            }}
        >
            <Drawer.Screen name="MainStack" component={MainStack} options={{ title: "Lieferanten" }} />
            <Drawer.Screen name="OrderHistoryStack" component={OrderHistoryStack} options={{ title: "Bestellungen" }} />
            <Drawer.Screen name="SettingsStack" component={SettingsStack} options={{ title: "Einstellungen" }} />
        </Drawer.Navigator>
    );
}

export default function NavigationCentral() {
    const { currentUser } = useAppContext()
    console.log("currentUser 🧙‍♂️", currentUser)

    return (
        <NavigationContainer linking={linking} ref={navigationRef}>
            {
                currentUser ?
                    (<MyDrawer />)
                    :
                    (
                        <UnAuthorizedStack />
                    )
            }
        </NavigationContainer >
    )
}