import React from 'react'
import { TouchableOpacity, View, Text } from 'react-native'
import { Button } from 'native-base'
import colors from '../styles/Colors'
const Tag = ({ active, title, action }) => {
    return (
        <Button onPress={action} backgroundColor={active ? colors.primary : "white"} borderWidth={1} borderColor={colors.primary} borderRadius="full" style={{ margin: 3, minWidth: 100, height: 30 }}>
            <View stylye={{ height: "100%", width: "100%", backgroundColor: "yellow" }}>
                <Text style={{ color: active ? "white" : "black", lineHeight: 14 }} >{title}</Text>
            </View>
        </Button>
        //     <TouchableOpacity onPress={action} style={{ height: 25, flexDirection: "row", justifyContent: "center", marginHorizontal: 10, marginVertical: 10, marginBottom: 30 }}>
        //         <Text color={"black"} style={{ textAlign: "center", borderBottomWidth: active ? 3 : "0", borderBottomColor: colors.primary }}>{title}</Text>
        //     </TouchableOpacity>
    )
}

export default Tag

