import { useNavigation } from '@react-navigation/core'
import React, { useState, useEffect, useRef } from 'react'
import { HeaderBackButton } from '@react-navigation/elements'
import { StyleSheet, ScrollView, Text, TouchableOpacity, View, TextInput, KeyboardAvoidingView } from 'react-native'
import { Box, FormControl, VStack, HStack } from 'native-base'
import { auth, db } from "../firebase"
import { useAppContext } from "../contexts/AppContext"
import StandartButton from '../components/StandartButton'
import ButtonBottomWrapper from '../components/ButtonBottomWrapper'
import StandartInput from '../components/StandartInput'
import StandartHeading from '../components/StandartHeading'
import SelectionOption from '../components/SelectionOption'
import { Input } from '../components/StandartInput';
import { usefullHeight } from '../Utils'

const AddSupplierScreenDetails = (props) => {
  const navigation = useNavigation()
  const { activeRestaurant } = useAppContext()
  const [orderAcceptanceMethod, setOrderAcceptanceMethod] = useState("email")
  const [orderAcceptanceEmail, setOrderAcceptanceEmail] = useState("")
  const [orderAcceptancePhone, setOrderAcceptancePhone] = useState("")
  const [salesRepresentativeMethod, setSalesRepresentativeMethod] = useState("whatsapp")
  const [salesRepresentativeName, setSalesRepresentativeName] = useState("")
  const [salesRepresentativePhone, setSalesRepresentativePhone] = useState("")
  const [salesRepresentativeEmail, setSalesRepresentativeEmail] = useState("")
  const salesRepresentativePhoneRef = useRef(null);

  useEffect(() => {
    console.log({ activeRestaurant })
  }, [activeRestaurant])

  const { supplierName, customerId, orderProccess } = props.route.params

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (<HeaderBackButton onPress={() => { props.navigation.navigate('AddSupplier') }} />),


    }, [props.navigation]
    );
  })

  function goToNextScreen() {

    let supplierDetails = {
      orderProccess,
      orderAcceptanceMethod,
      orderAcceptanceEmail,
      orderAcceptancePhone,
      salesRepresentativeMethod,
      salesRepresentativeName,
      salesRepresentativePhone,
      salesRepresentativeEmail
    }

    let supplierInfo = {
      name: supplierName,
      customerId,
      orderProccess,
      supplierDetails
    }

    navigation.navigate("AddSupplierUpload", { supplierInfo })
  }

  function validate() {
    if (orderProccess == "orderAcceptance") {
      if (orderAcceptanceMethod == "email" && orderAcceptanceEmail !== "") {
        return true
      } else if (orderAcceptanceMethod == "phone" && orderAcceptancePhone !== "") {
        return true
      } else if (orderAcceptanceMethod == "webshopApp") {
        return true
      } else {
        return false
      }
    } else if (orderProccess == "salesRepresentative") {
      if (setSalesRepresentativeName === "" || setSalesRepresentativeEmail === "") {
        return false
      } else {
        if (salesRepresentativeMethod == "email") {
          if (salesRepresentativeEmail === "") {
            return false
          }
        } else {
          return true
        }
      }
    }
    return true
  }

  return (
    <KeyboardAvoidingView enabled={false} style={{
      minHeight: usefullHeight, alignItems: "center"
    }} >
      <Box w={["90%", "90%", "600px"]} mt={usefullHeight * 0.05}>
        {
          orderProccess == "orderAcceptance" &&
          <>
            <HStack my="7px">
              <FormControl isRequired w={"100%"} px="5px">
                <FormControl.Label _text={{
                  bold: true
                }}>Wie schickst du deine Bestellung an die Bestellannahme?</FormControl.Label>
                <VStack>
                  <SelectionOption title={"E-Mail"} active={orderAcceptanceMethod == "email"} action={() => setOrderAcceptanceMethod("email")} />
                  <SelectionOption title={"Anruf"} active={orderAcceptanceMethod == "phone"} action={() => setOrderAcceptanceMethod("phone")} />
                  <SelectionOption title={"Webshop/App"} active={orderAcceptanceMethod == "webshopApp"} action={() => setOrderAcceptanceMethod("webshopApp")} />
                </VStack>
              </FormControl>
            </HStack>
            {orderAcceptanceMethod == "email" &&
              <HStack my="7px">
                <FormControl isRequired w={"100%"} px="5px">
                  <FormControl.Label _text={{
                    bold: true
                  }}>E-Mail Adresse der Bestellannahme</FormControl.Label>
                  <Input
                    placeholder="mail@bestellannahme.de"
                    // w={["90%", "90%", "500"]}
                    //borderRadius="10"
                    //borderColor="#2563EB"
                    //borderWidth="2"
                    overflow="hidden"
                    returnKeyType="done"
                    //value={restaurantName}
                    onChange={(e) => setOrderAcceptanceEmail(e.target.value)}
                  />
                </FormControl>
              </HStack>
            }
            {orderAcceptanceMethod == "phone" &&
              <HStack my="7px">
                <FormControl isRequired w={"100%"} px="5px">
                  <FormControl.Label _text={{
                    bold: true
                  }}>Telefonnummer der Bestellannahme</FormControl.Label>
                  <Input

                    placeholder="+49 172123456"
                    // w={["90%", "90%", "500"]}
                    //borderRadius="10"
                    //borderColor="#2563EB"
                    //borderWidth="2"
                    returnKeyType="done"
                    onChange={(e) => setOrderAcceptancePhone(e.target.value)}
                    overflow="hidden"
                  //value={restaurantName}

                  //onChange={(e) => setRestaurantName(e.target.value)}
                  />
                </FormControl>
              </HStack>
            }
          </>
        }
        {
          orderProccess == "salesRepresentative" &&
          <>
            <HStack my="7px">
              <FormControl isRequired w={"100%"} px="5px">
                <FormControl.Label _text={{
                  bold: true
                }}>Name des Außendienstmitarbeiters</FormControl.Label>
                <Input
                  placeholder="Max Mustermann"
                  // w={["90%", "90%", "500"]}
                  //borderRadius="10"
                  //borderColor="#2563EB"
                  //borderWidth="2"
                  overflow="hidden"
                  returnKeyType="next"
                  onSubmitEditing={() => salesRepresentativePhoneRef.current.focus()}
                  //value={restaurantName}
                  onChange={(e) => setSalesRepresentativeName(e.target.value)}
                />
              </FormControl>
            </HStack>
            <HStack my="7px">
              <FormControl isRequired w={"100%"} px="5px">
                <FormControl.Label _text={{
                  bold: true
                }}>Telefonnummer des Außendienstmitarbeiters</FormControl.Label>
                <Input
                  placeholder="0174123456"
                  // w={["90%", "90%", "500"]}
                  //borderRadius="10"
                  //borderColor="#2563EB"
                  //borderWidth="2"
                  overflow="hidden"
                  ref={salesRepresentativePhoneRef}
                  returnKeyType="done"
                  //value={restaurantName}
                  onChange={(e) => setSalesRepresentativePhone(e.target.value)}
                />
              </FormControl>
            </HStack>
            <HStack my="7px">
              <FormControl isRequired w={"100%"} px="5px">
                <FormControl.Label _text={{
                  bold: true
                }}>Wie sendest du deine Bestellung?</FormControl.Label>
                <VStack>
                  <SelectionOption title={"WhatsApp"} active={salesRepresentativeMethod == "whatsapp"} action={() => setSalesRepresentativeMethod("whatsapp")} />
                  <SelectionOption title={"Anruf"} active={salesRepresentativeMethod == "phone"} action={() => setSalesRepresentativeMethod("phone")} />
                  <SelectionOption title={"E-Mail"} active={salesRepresentativeMethod == "email"} action={() => setSalesRepresentativeMethod("email")} />
                </VStack>
              </FormControl>
            </HStack>
            {salesRepresentativeMethod == "email" &&
              <HStack my="7px">
                <FormControl isRequired w={"100%"} px="5px">
                  <FormControl.Label _text={{
                    bold: true
                  }}>E-Mail Adresse</FormControl.Label>
                  <Input
                    placeholder="mail@maxmustermann.de"
                    // w={["90%", "90%", "500"]}
                    //borderRadius="10"
                    //borderColor="#2563EB"
                    //borderWidth="2"
                    overflow="hidden"
                    returnKeyType="done"
                    //value={restaurantName}
                    onChange={(e) => setSalesRepresentativeEmail(e.target.value)}
                  />
                </FormControl>
              </HStack>
            }
          </>
        }
      </Box>
      <ButtonBottomWrapper>
        <StandartButton action={() => { goToNextScreen() }} disabled={!validate()}></StandartButton>
      </ButtonBottomWrapper>

    </KeyboardAvoidingView>
  )
}

export default AddSupplierScreenDetails

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
  standartFormText: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 16
  }
})
