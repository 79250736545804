import { useNavigation } from '@react-navigation/core'
import React, { useEffect, useState, useRef } from 'react'
import { KeyboardAvoidingView, StyleSheet, Text, TextInput, TouchableOpacity, View, Button, Platform } from 'react-native'
import { useAppContext } from "../contexts/AppContext"
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';
import { getAuth, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { initializeApp, getApp } from 'firebase/app';
import PhoneInput, { getNumberAfterPossiblyEliminatingZero } from "react-native-phone-number-input";
import StandartButton from '../components/StandartButton'
import ButtonBottomWrapper from '../components/ButtonBottomWrapper'
import StandartInput from '../components/StandartInput'
import StandartHeading from '../components/StandartHeading'
import { Box } from 'native-base';
import colors from '../styles/Colors';

// Firebase references
const app = getApp();
const auth = getAuth();
const SignUpScreen = () => {

  const { signup } = useAppContext()
  const [error, setError] = useState("")
  const phoneInput = useRef(null);
  const navigation = useNavigation()
  const recaptchaVerifier = React.useRef(null);

  const firebaseConfig = app ? app.options : undefined;
  const [message, showMessage] = React.useState();
  const attemptInvisibleVerification = false;

  const requestVerificationCode = async () => {
    // The FirebaseRecaptchaVerifierModal ref implements the
    // FirebaseAuthApplicationVerifier interface and can be
    // passed directly to `verifyPhoneNumber`.
    try {
      const phoneNumber = phoneInput.current?.getNumberAfterPossiblyEliminatingZero(phoneInput.current.value).formattedNumber
      console.log("my phone number is", phoneNumber)
      const phoneProvider = new PhoneAuthProvider(auth);
      console.log("phoneProvider", phoneProvider)
      console.log("recaptchaVerifier.current", recaptchaVerifier.current)
      const verificationId = await phoneProvider.verifyPhoneNumber(
        phoneNumber,
        recaptchaVerifier.current,
        true
      );
      console.log("verificationId", verificationId)
      showMessage({
        text: 'Verification code has been sent to your phone.',
      });
      navigation.navigate("PhoneCode", { verificationId })
    } catch (err) {
      console.log(err)
      showMessage({ text: `Error: ${err.message}`, color: 'red' });
    }
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      enabled={false}
    >
      <StandartHeading>Wie ist deine Mobilnummer?</StandartHeading>
      <View style={styles.inputContainer}>

        <Box w={["95%", "95%", "500"]} >
          <PhoneInput
            ref={phoneInput}
            defaultCode="DE"
            disableArrowIcon={true}
            placeholder="01741235678"
            layout="second"
            autoFocus
            containerStyle={{ width: "100%", borderRadius: 10, borderColor: colors.primary, borderWidth: 2, overflow: "hidden" }}
            textInputStyle={{ outline: "none" }}
            countryPickerButtonStyle={{ width: 48, height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          />
          {message &&

            <Text style={{ color: "red" }}>

              {message.text}
            </Text>

          }
          <Box mx="auto" mt="40px">
            <FirebaseRecaptchaVerifierModal
              ref={recaptchaVerifier}
              firebaseConfig={app.options}
            // attemptInvisibleVerification
            />
            {attemptInvisibleVerification && <FirebaseRecaptchaBanner />}
          </Box>
        </Box>


      </View>
      <ButtonBottomWrapper>
        <StandartButton action={() => { requestVerificationCode() }}></StandartButton>
      </ButtonBottomWrapper>

    </KeyboardAvoidingView>
  )
}

export default SignUpScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  input: {
    backgroundColor: 'white',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    marginTop: 5,
  },
  buttonContainer: {
    width: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  button: {
    backgroundColor: '#0782F9',
    width: '100%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
  },
  buttonOutline: {
    backgroundColor: 'white',
    marginTop: 5,
    borderColor: '#0782F9',
    borderWidth: 2,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
  buttonOutlineText: {
    color: '#0782F9',
    fontWeight: '700',
    fontSize: 16,
  },
})
