import { useNavigation } from '@react-navigation/core'
import React, { useState, useEffect, useRef } from 'react'

import { StyleSheet, TouchableOpacity, View, TextInput, ScrollView } from 'react-native'
import { VStack, HStack, Box, FormControl, Button, Text } from 'native-base'
import { timestamp } from '../Utils';

import { useAppContext } from "../contexts/AppContext"
import StandartButton from '../components/StandartButton'
import ButtonBottomWrapper from '../components/ButtonBottomWrapper'
import StandartInput from '../components/StandartInput'
import StandartHeading from '../components/StandartHeading'
import { auth, db } from "../firebase"
import RestaurantAutoComplete from "../components/RestaurantAutoComplete"
import { Input } from '../components/StandartInput';
//import styled from 'styled-components/native';


// const Input = styled.TextInput`
//   border: 1px solid black;
// `
const AddRestaurantScreen = (props) => {

  const navigation = useNavigation()
  const { addSupplier, activeRestaurant, setActiveRestaurant, updateSupplierConnection, currentUser } = useAppContext()
  const [restaurantName, setRestaurantName] = useState("");
  const [prename, setPrename] = useState("")
  const [surname, setSurname] = useState("")
  const [street, setStreet] = useState("")
  const [streetNumber, setStreetNumber] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [email, setEmail] = useState("")
  const [abc, setABC] = useState()
  const [isMounted, setIsMounted] = useState(false)
  const [errors, setErrors] = useState({});

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (<></>)
    });
  }, [props.navigation]);

  const validate = () => {

    if (restaurantName === undefined || restaurantName === "") {
      setErrors({
        restaurantName: 'restaurantName is required'
      });
      return false;
    }
    if (prename === undefined || prename === "") {
      setErrors({
        prename: 'prename is required'
      });
      return false;
    }
    if (surname === undefined || surname === "") {
      setErrors({
        surname: 'surname is required'
      });
      return false;
    }
    if (street === undefined || street === "") {
      setErrors({
        ...errors,
        street: 'street is required'
      });
      return false;
    }
    if (streetNumber === undefined || streetNumber === "") {
      setErrors({
        streetNumber: 'streetNumber is required'
      });
      return false;
    }
    if (postalCode === undefined || postalCode === "") {
      setErrors({
        postalCode: 'postalCode is required'
      });
      return false;
    }
    if (city === undefined || city === "") {
      setErrors({
        city: 'city is required'
      });
      return false;
    }
    if (email === undefined || email === "") {
      setErrors({
        email: 'email is required'
      });
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   setIsMounted(true)
  //   return () => { setIsMounted(false) }; // cleanup toggles value, if unmounted
  // }, []);

  // function continueWithCustomerId(supplier) {
  //   //var newCustomerId = customerIdRef.current.value
  //   //console.log(newCustomerId)
  //   updateSupplierConnection(supplier, 111)
  //   navigation.navigate("AddSupplierUpload")
  //   //history.push("/products")
  // }

  function placeClicked(details) {
    const addressComponents = details.address_components
    const name = details.name

    if (name) {
      setRestaurantName(name)
    }

    let streetNumberObj = addressComponents.find((obj => obj.types.includes("street_number")));
    if (streetNumberObj) {
      let streetNumber = streetNumberObj.long_name
      setStreetNumber(streetNumber)
    }

    let streetObj = addressComponents.find((obj => obj.types.includes("route")));
    if (streetObj) {
      let street = streetObj.long_name
      setStreet(street)
    }

    let postalCodeObj = addressComponents.find((obj => obj.types.includes("postal_code")));
    if (postalCodeObj) {
      let postalCode = postalCodeObj.long_name
      setPostalCode(postalCode)
    }

    let cityObj = addressComponents.find((obj => obj.types.includes("locality")));
    if (cityObj) {
      let city = cityObj.long_name
      setCity(city)
    }
  }

  function addRestaurant() {

    if (!validate()) {
      return
    }

    var address = {
      prename,
      surname,
      street,
      streetNumber,
      postalCode,
      city
    }

    let time = timestamp()
    let newRestaurant = { address, name: restaurantName, email: email, admins: [currentUser.uid], members: [currentUser.uid], upatedTime: time, addedTime: time }
    db.collection("restaurants").add(newRestaurant)
      .then((docRef) => {
        setActiveRestaurant({ id: docRef.id, ...newRestaurant })
        console.log("Document written with ID: ", docRef.id);
        navigation.navigate("AddSupplier")
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }


  return (
    <>
      <ScrollView contentContainerStyle={{ minHeight: "100%", marginBottom: 100 }}>
        <View style={styles.container}>

          {/* <TextInput
        onChangeText={setRestaurantName}
        value={restaurantName}
        placeholder="Lieferanten Name"
        keyboardType="numeric"
      /> */}
          {/* <StandartHeading>Wie heißt dein Restaurant?</StandartHeading> */}

          <Box w={["90%", "90%", "600px"]}>

            <VStack w="100%" px="5px" style={{ position: "absolute", top: 28, zIndex: 999 }}>
              <FormControl.Label _text={{
                bold: true
              }}>Suche dein Restaurant:</FormControl.Label>
              <RestaurantAutoComplete onPlaceClicked={placeClicked} />
            </VStack>

            <HStack my="7px" mt="140px">
              <FormControl isRequired w={"100%"} isInvalid={'restaurantName' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>Unternehmensname</FormControl.Label>
                <Input

                  placeholder="Mein Restaurant"
                  // w={["90%", "90%", "500"]}
                  //borderRadius="10"
                  //borderColor="#2563EB"
                  //borderWidth="2"
                  overflow="hidden"
                  value={restaurantName}
                  onChange={(e) => setRestaurantName(e.target.value)}
                />
                {'restaurantName' in errors && <FormControl.ErrorMessage>Bitte gib einen Namen für dein Restaurant ein</FormControl.ErrorMessage>}
              </FormControl>
            </HStack>

            <HStack my="7px">

              <FormControl isRequired w={"50%"} px="5px" isInvalid={'prename' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>Vorname</FormControl.Label>
                <Input placeholder="Max" onChange={(e) => setPrename(e.target.value)} />
                {'prename' in errors && <FormControl.ErrorMessage>Bitte gib deinen Vornamen ein</FormControl.ErrorMessage>}
              </FormControl>


              <FormControl isRequired w={"50%"} px="5px" isInvalid={'surname' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>Nachname</FormControl.Label>
                <Input placeholder="Mustermann" onChange={(e) => setSurname(e.target.value)} />
              </FormControl>
            </HStack>
            <Text>
              {'surname' in errors && <Text style={{ color: "#DC2626", fontSize: 12 }} px="5px" my="7px" >Bitte gib deinen Nachnamen ein</Text>}
            </Text>
            <HStack my="7px">
              <FormControl isRequired w={"80%"} px="5px" isInvalid={'street' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>Straße</FormControl.Label>
                <Input placeholder="Musterstraße" onChange={(e) => setStreet(e.target.value)} value={street} />
                {'street' in errors && <FormControl.ErrorMessage>Bitte die Straße deines Restaurants ein</FormControl.ErrorMessage>}
              </FormControl>
              <FormControl isRequired w={"20%"} px="5px" isInvalid={'streetNumber' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>Nr</FormControl.Label>
                <Input placeholder="1" onChange={(e) => setStreetNumber(e.target.value)} value={streetNumber} />
              </FormControl>
            </HStack>
            <Text>
              {'streetNumber' in errors && <Text style={{ color: "#DC2626", fontSize: 12 }} px="5px" my="7px" >Bitte die Hausnummer deines Restaurants ein</Text>}
            </Text>
            <HStack my="7px">
              <FormControl isRequired w={"40%"} px="5px" isInvalid={'postalCode' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>PLZ</FormControl.Label>
                <Input placeholder="12345" onChange={(e) => setPostalCode(e.target.value)} value={postalCode} />
                {'postalCode' in errors && <FormControl.ErrorMessage>Bitte die Postleitzahl deines Restaurants ein</FormControl.ErrorMessage>}
              </FormControl>
              <FormControl isRequired w={"60%"} px="5px" isInvalid={'city' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>Stadt</FormControl.Label>
                <Input placeholder="Musterstadt" onChange={(e) => setCity(e.target.value)} value={city} />

              </FormControl>
            </HStack>
            <Text>
              {'city' in errors && <Text style={{ color: "#DC2626", fontSize: 12 }} px="5px" my="7px" >Bitte die Stadt deines Restaurants ein</Text>}
            </Text>
            <HStack my="7px">
              <FormControl isRequired w={"100%"} px="5px" isInvalid={'email' in errors}>
                <FormControl.Label _text={{
                  bold: true
                }}>E-Mail</FormControl.Label>
                <Input placeholder="meine@email.de" onChange={(e) => setEmail(e.target.value)} />
                {'email' in errors && <FormControl.ErrorMessage>Bitte deine E-Mail-Adresse ein</FormControl.ErrorMessage>}
              </FormControl>
            </HStack>
          </Box>


        </View >
      </ScrollView>
      <ButtonBottomWrapper>
        <StandartButton action={() => { addRestaurant() }}></StandartButton>
      </ButtonBottomWrapper>
    </>
  )
}

export default AddRestaurantScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
})
