import React from 'react'
import { Box, Text } from 'native-base';
import { useFonts, Inter_900Black } from '@expo-google-fonts/inter';

const StandartHeading = ({ children }, absolute = false) => {
    let [fontsLoaded] = useFonts({
        Inter_900Black,
    });
    return (
        <>
            {absolute ?
                <Box display="flex" justifyContent="center" alignItems="center" w="100%" position="absolute" top="10" left="0">
                    <Text style={{ fontFamily: 'Inter_900Black' }} fontSize="4xl" textAlign="center" maxWidth="80%">{children}</Text>
                </Box>
                :
                <Box display="flex" justifyContent="center" alignItems="center" w="100%">
                    <Text style={{ fontFamily: 'Inter_900Black' }} fontSize="4xl" textAlign="center" maxWidth="80%">{children}</Text>
                </Box>

            }

        </>
    )
}

export default StandartHeading
// 