import React, { useState, useEffect, Component } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Input, HStack, Box, VStack, Text, Spacer, ScrollView } from 'native-base';
import { Entypo, Ionicons } from '@expo/vector-icons';
import { AppContext } from '../contexts/AppContext';

import isEqual from 'lodash.isequal';
import { unitDict } from '../Utils'
import { navigate } from '../RootNavigation';
import colors from '../styles/Colors';

const defaultUnit = "Kiste"
class InnerOrderItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
        this.incrementUpInterval = false
    }

    updateAmount(newAmount, unit) {
        // this.setState({
        //     amount: newAmount
        // })
        let localOrder = {
            ...this.props.item,
            orderDetails: [
                { amount: newAmount, unit: unit }
            ],
            supplier: this.props.value.selectedSupplier.id
        }
        console.log("update amount called")
        this.props.updateOrders(localOrder)

    }

    // shouldComponentUpdate(nextProps) {
    //     const { value, item } = nextProps
    //     var returnValue = false
    //     if (value, item) {
    //         console.log("myasd", value)
    //         let valueFromState = this.findValueInSavedState(value)
    //         if (this.state && valueFromState > 0 && valueFromState !== this.state.amount) {
    //             returnValue = true
    //         }
    //     }
    //     return returnValue
    // }

    // shouldComponentUpdate(nextProps) {

    //     //console.log("my", nextProps)
    //     return false
    //     //return !isEqual(this.props.item, nextProps.item);
    // }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.amount !== this.props.amount) {
            //this.setState({ amount: nextProps.amount })
            return true
        }
        else if (nextState.expanded !== this.state.expanded) {
            return true
        }
        else if (nextProps.item.unit !== this.props.item.unit) {
            return true
        }
        else if (nextProps.item.name !== this.props.item.name) {
            return true
        }
        else {
            return false
        }
        //console.log("my", nextProps)
        //return false
        //return !isEqual(this.props.item, nextProps.item);
    }

    updateState() {
        this.setState({ expanded: !this.state.expanded, active: true })
    }

    updateUnit(article, unit) {
        this.props.value.updateArticle({ ...article, unit }, this.props.value.selectedSupplier.id)
        this.setState({ unit, expanded: false })
    }

    getUnit(item) {
        if (item.unit) {
            return item.unit
        } else {
            return defaultUnit
        }
    }

    updateAmountWithKeyboardInput(inputValue) {
        if (inputValue === "") {
            this.updateAmount(0)
        } else {
            if (parseInt(inputValue)) {
                this.updateAmount(parseInt(inputValue))
            }
        }
    }

    deleteProduct() {
        this.updateAmount(0)
    }

    // handlePressIn() {
    //     console.log("handlePressIn")
    //     const interval = setInterval(() => {
    //         this.updateAmount((amount + 1), unit)
    //     }, 500);
    //     return () => clearInterval(interval);
    // }

    // handlePressOut() {
    //     console.log("handlePressOut")
    // }

    render() {
        console.log("i am rerendering 🔴")
        let expanded = this.state.expanded
        //let unit = this.state.unit
        const { item, amount, active, version, availableUnits } = this.props
        let unit = this.getUnit(item)
        let translatedUnit = unitDict(unit)
        let finalAvailableUnits = ["Kiste", "Stück"]
        if (availableUnits) {
            finalAvailableUnits = availableUnits.split(", ")
        }
        return (
            <Box style={styles.item} h={expanded === true ? "120px" : "80px"} w="100%" justifyContent="flex-start" borderBottomWidth="1" borderColor="gray.300" alignItems="center" p="0">
                <View style={{ width: "100%", height: "100%", flexDirection: "column", alignItems: "space-between", width: "100%", justifyContent: "center", marginHorizontal: "auto" }}>
                    <HStack alignItems={"center"} w="100%" justifyContent={"space-between"}>
                        <TouchableOpacity onPress={() => this.updateState()} style={{ width: version !== "check" ? "60%" : "50%" }}>
                            <VStack w="100%" pl="2.5%">
                                <Text>{item.name}</Text>
                                {expanded && item.articleNr && item.articleNr !== "" && <Text fontSize="xs" color="gray.500">ArtikelNr.: {item.articleNr}</Text>}
                            </VStack>
                        </TouchableOpacity>
                        {(active || expanded) ?
                            <HStack alignItems={"center"} pr="2.5%">
                                <HStack borderWidth="1" borderColor="gray.300" borderRadius="3px" ml="15px" h="55px">
                                    <TouchableOpacity onPress={() => { amount > 0 && this.updateAmount((amount - 1), unit) }} style={{ justifyContent: "center", paddingHorizontal: 8 }} >
                                        <Entypo name="minus" size={18} color="grey" />
                                    </TouchableOpacity>
                                    <VStack borderLeftWidth="1" borderRightWidth="1" borderColor="gray.300" w="50px">
                                        <TouchableOpacity activeOpacity={1} style={{ height: 34, width: "100%" }}>
                                            <Input value={(amount).toString()} keyboardType="numeric" onChange={(e) => { this.updateAmountWithKeyboardInput(e.target.value) }} w="100%" h="100%" textAlign={'center'} borderRadius="0" borderWidth="0" borderBottomWidth="1" borderColor="gray.300" fontWeight="bold" fontSize="14" />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => this.updateState()}>
                                            {<Text textAlign="center" mb="2px" fontSize="xs" color="gray.400">{translatedUnit}</Text>}
                                        </TouchableOpacity>
                                    </VStack>
                                    <TouchableOpacity onPress={() => { this.updateAmount((amount + 1), unit) }} style={{ justifyContent: "center", paddingHorizontal: 8 }} >
                                        <Entypo name="plus" size={18} color="grey" />
                                    </TouchableOpacity>
                                </HStack>
                                {version === "check" &&
                                    <TouchableOpacity onPress={() => { this.deleteProduct() }} style={{ paddingLeft: 15 }} >
                                        <Ionicons name="trash-outline" size={22} color="grey" />
                                    </TouchableOpacity>
                                }
                            </HStack>

                            :
                            <HStack w="70px">
                                <Text>{amount} x {translatedUnit}</Text>
                            </HStack>
                        }
                    </HStack>
                    {expanded &&
                        <HStack w="100%" justifyContent="space-between" pl="2.5%">
                            {version !== "check" ? <TouchableOpacity style={{ marginTop: 10 }} onPress={() => { navigate("EditProduct", { article: this.props.item }); this.setState({ expanded: false }) }}><Text style={{ color: colors.primary, textDecorationLine: "underline" }} font="xs">Produkt bearbeiten</Text></TouchableOpacity> : <View></View>}
                            <HStack mt="5px" ml="5px" w="150px" mr={version === "check" ? "10px" : 0}>
                                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                                    {finalAvailableUnits.map((availableUnit) => {
                                        return (
                                            <TouchableOpacity style={[styles.defaultUnitSelection, (unit === availableUnit && styles.activeUnitSelection)]} onPress={() => { this.updateUnit(item, availableUnit); this.updateAmount(amount, availableUnit) }}>
                                                <Text style={[styles.defaultTextUnitSelection, (unit === availableUnit && styles.activeTextUnitSelection)]}>{availableUnit}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                    }
                                </ScrollView>
                            </HStack>
                        </HStack>
                    }
                </View >
            </Box >

        )
    }
}

export default class TestOrderItem extends Component {

    findValueInSavedState(value) {
        var savedValue = 0
        let selectedSupplier = value.selectedSupplier
        let currentOrders = value.currentOrders
        if (!selectedSupplier || !currentOrders) {
            return savedValue
        }

        let thisOrder = currentOrders[selectedSupplier.id]


        if (thisOrder) {
            let currentItem = thisOrder[this.props.item.id]
            if (currentItem) {
                savedValue = currentItem.orderDetails[0].amount
            }
        }
        return savedValue
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return false
    // }

    render() {
        const { item, active, version } = this.props;


        //console.log("rerender 🧅🔴🔴🔴🔴🔴")
        return (
            <AppContext.Consumer>
                {value =>
                    <InnerOrderItem item={item} amount={this.findValueInSavedState(value)} value={value} updateOrders={value.updateOrders} active={active} version={version} availableUnits={item.availableUnits} />
                    //<View style={{ height: 80 }}>{console.log("i am rendering 🔴")}<Text>{item.name}</Text></View>
                }
            </AppContext.Consumer>

        );
    }
}

// navigation prop

// const OrderItem = React.memo((props) => {
//     const { item } = props
//     const [amount, setAmount] = useState(0)




//     console.log("on render 🧪❗️")
//     return (
//         <AppContext.Consumer>
//             <Box style={styles.item} >
//                 <HStack justifyContent={"space-around"}>
//                     <Text>{item.name}</Text>
//                     <HStack>
//                         <TouchableOpacity onPress={() => { amount > 0 && updateAmount(amount - 1) }}>
//                             <Entypo name="minus" size={24} color="grey" />
//                         </TouchableOpacity>
//                         <Input value={amount} w="40px" />
//                         <TouchableOpacity onPress={() => { updateAmount((amount + 1)) }}>
//                             <Entypo name="plus" size={24} color="grey" />
//                         </TouchableOpacity>
//                     </HStack>
//                 </HStack>
//             </Box>
//         </AppContext.Consumer>
//     )
// })

const styles = StyleSheet.create({
    item: {
        //borderBottomColor: '#d3d3d3',
        //borderBottomWidth: 1,
        justifyContent: 'center',
        backgroundColor: 'white',
        //marginVertical: 8,
        padding: 20,
    },
    defaultUnitSelection: {
        backgroundColor: "white",
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderRadius: 20,
        margin: 5,
        borderColor: colors.primary,
        borderWidth: 0.5
    },
    activeUnitSelection: {
        backgroundColor: colors.primary,
    },
    defaultTextUnitSelection: { color: "black" },
    activeTextUnitSelection: { color: "white" },
    title: {
        fontSize: 32,
    },
});

