import { useNavigation } from '@react-navigation/core'
import React, { useEffect } from 'react'
import { SafeAreaView, StyleSheet, TouchableOpacity, View, ScrollView, StatusBar } from 'react-native'
import { useAppContext } from "../contexts/AppContext"
import { Box, Text, Center, Button, Image } from 'native-base'
import StandartHeading from '../components/StandartHeading'
import DrawerMenuIcon from '../components/DrawerMenuIcon'


const SupplierSelectionScreen = (props) => {
  const navigation = useNavigation()
  const { currentUser, userDetails, suppliers, findUserInRestaurant, setSelectedSupplier } = useAppContext()

  useEffect(() => {
    currentUser?.uid && findUserInRestaurant(currentUser.uid)
  }, [])

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (
        <DrawerMenuIcon navigation={props.navigation} />
      )
    });
  }, [props.navigation]);

  function navigateToNextStep(supplier) {
    if (supplier.status === "ready") {
      navigation.navigate("ProductSelection")
    }
    // else if (!supplier.files) {
    //   navigation.navigate("AddSupplierUpload")
    // }
    else {
      navigation.navigate("AddSupplierWait")
    }

  }
  console.log({ suppliers })

  // return (
  //   <View>
  //     <Text>Hallo</Text>
  //   </View>
  // )

  return (
    <>
      {/* <SafeAreaView style={styles.container}> */}

      {/* {userDetails?.supplierConnection && userDetails.supplierConnection.map((supplier, index) => {
        return <TouchableOpacity style={styles.supplierTile} key={index} onPress={() => navigation.navigate("ProductSelection")}><Text>{supplier.supplier}</Text></TouchableOpacity>
      })} */}
      {/* <StandartHeading>Lieferanten</StandartHeading> */}

      <ScrollView contentContainerStyle={styles.container}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" maxWidth="900px">
          {
            suppliers.map((supplier, index) => {
              return (
                <Button onPress={() => { setSelectedSupplier(supplier); navigateToNextStep(supplier) }} bg="white" w={["150px", "160px", "170px"]} h={["150px", "160px", "170px"]} key={"supplier-" + index} borderWidth="1" borderColor="cyan.500" m="3" borderRadius="md" shadow="1">
                  {console.log("supplier.url", supplier.url)}
                  <Center w="100%" h="100%">
                    {supplier.url ?
                      <Image source={{ uri: supplier.url }} w="140px" h="140px" mx="auto" resizeMode="contain" alt={supplier.name} /> :
                      <Text maxWidth={"130px"} textAlign="center">{supplier.name}</Text>
                    }
                    {supplier.status === "pending" && <Text>⏳ wird bearbeitet</Text>}
                  </Center>
                </Button>
              )
            })
          }
          <Button onPress={() => { navigation.navigate("AddSupplier") }} bg="light.100" w={["150px", "160px", "170px"]} h={["150px", "160px", "170px"]} borderWidth="1" borderColor="cyan.500" m="3" borderRadius="md" shadow="1">
            <Center w="100%" h="100%">
              <Text>{"+ Hinzufügen"}</Text>
            </Center>
          </Button>
        </Box>
      </ScrollView>



      {/* </SafeAreaView> */}
    </>
  )
}

export default SupplierSelectionScreen

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: StatusBar.currentHeight,
    minHeight: '100%'

  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
  supplierTile: {
    width: 100,
    height: 100
  }
})
