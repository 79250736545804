import React, { useEffect } from 'react'
import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View, FlatList } from 'react-native'
import { Box, Text, VStack, Divider, HStack, Spacer, Button } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { useAppContext } from '../contexts/AppContext';
import Ionicons from '@expo/vector-icons/Ionicons';
import { HeaderBackButton } from '@react-navigation/elements'
import moment from "moment";
import StandartHeading from '../components/StandartHeading';
import { standartDate } from '../Utils';
import DrawerMenuIcon from '../components/DrawerMenuIcon'
import { AppStyles } from '../styles/AppStyles'
import colors from '../styles/Colors';

function OrderHistoryCard(props) {
  const { item, navigation } = props
  // useEffect(() => {
  //   props.navigation.setOptions({
  //     headerLeft: () => (<HeaderBackButton onPress={() => { props.navigation.navigate('SupplierSelection') }} />)
  //   });
  // }, [props.navigation]);
  return (
    <Box my="10px" w="95%" mx="auto" border="1" borderRadius="md" bg="white" shadow="1">
      <VStack space="4" divider={<Divider />}>
        <Box px="4" pt="4">
          <HStack justifyContent="space-between" alignItems="center">
            <HStack>
              <Text mr="20px" fontWeight="bold">{item.supplier.name}</Text>
              <Text fontWeight="bold">{standartDate(item.timestamp)}</Text>
            </HStack>
            {item.confirmed && <HStack alignItems="center"><Ionicons name="md-checkmark-circle-outline" size={18} color="#7bd627" /><Text ml="8px" color="#7bd627" fontWeight="bold" >Bestellung bestätigt </Text></HStack>}
          </HStack>
        </Box>
        <Box p="4">
          <Text>{Object.keys(item.articles).length} Artikel</Text>
          <HStack>
            <Spacer />
            <Button onPress={() => navigation.navigate("OrderHistoryDetail", { id: item.id })} backgroundColor={colors.primary}>
              Ansehen
            </Button>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
}

const OrderHistory = (props) => {
  const navigation = useNavigation()
  const { pastOrders, fetchPastOrders, activeRestaurant } = useAppContext()
  console.log({ pastOrders })

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (
        <DrawerMenuIcon navigation={props.navigation} />
      )
    });
  }, [props.navigation]);

  useEffect(() => {
    fetchPastOrders()
  }, [activeRestaurant])
  return (
    <SafeAreaView style={AppStyles.container}>
      {/* <Box h="200px">
        <StandartHeading>Bestellungen</StandartHeading>
      </Box> */}
      <FlatList
        data={pastOrders}
        //‚initialNumToRender={30}
        renderItem={({ item }) =>


          <OrderHistoryCard item={item} navigation={navigation} />
        }

        keyExtractor={item => item.id}
        style={{ marginTop: 30 }}
      //getItemCount={getItemCount}
      //getItem={getItem}
      />
    </SafeAreaView>
  )
}

export default OrderHistory

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight,
  },
  item: {
    borderBottomColor: '#d3d3d3',
    borderBottomWidth: 2,
    height: 70,
    justifyContent: 'center',
    marginVertical: 8,
    padding: 20,
  },
  title: {
    fontSize: 32,
  },
});
