import { Platform } from "react-native"
import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/storage"
import { initializeAuth } from "firebase/auth"
import { getReactNativePersistence } from "firebase/auth/react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"


const app = firebase.initializeApp({
  apiKey: "AIzaSyAlt-Bghxa1t5kkLLr-F20xgKemiDAAVe0",
  authDomain: "AIzaSyA_13u2AX12exJ_3Ltn28lAaO9hWeFxe9M",
  projectId: "fillmeapp-development",
  storageBucket: "fillmeapp-development.appspot.com",
  messagingSenderId: "415898103064",
  appId: "1:415898103064:web:a9b37284fdf086bcd0826d"
})

// const auth = app.auth({
//   persistence: getReactNativePersistence(AsyncStorage)
// })



initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage)
});

const auth = app.auth();
auth.languageCode = 'de';
//auth.setPersistence('local')

const db = firebase.firestore();
const functions = app.functions('europe-west1');
const storage = app.storage();

let DEBUG = false

if (window?.location?.hostname === 'localhost' || Platform.OS === "ios" && DEBUG) {
  console.log("DEBUG Mode")
  db.useEmulator('localhost', 8999);
  auth.useEmulator('http://localhost:9099/', { disableWarnings: true });
  functions.useEmulator("localhost", 5001);
  storage.useEmulator("localhost", 9199);
}

if (Platform.OS === "android" && DEBUG) {
  db.useEmulator('10.0.2.2', 8999);
  auth.useEmulator('http://10.0.2.2:9099', { disableWarnings: true });
  functions.useEmulator("10.0.2.2", 5001);
  storage.useEmulator("10.0.2.2", 9199);
}




// else if (window.location.hostname === 'mac-2.local') {
//   console.log("DEBUG Mode")
//   db.useEmulator('mac-2.local', 8999);
//   auth.useEmulator('mac-2.local', 9099, { disableWarnings: true });
//   functions.useEmulator("mac-2.local", 5001);
//   storage.useEmulator("mac-2.local", 9199);
// }
//db.settings({ experimentalForceLongPolling: true });

export default app
export { db, auth, functions, storage };