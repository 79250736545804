import React, { useContext, useState, useEffect } from "react"
import { auth, db } from "../firebase"
import { nestedObject } from "../Utils";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as RootNavigation from '../RootNavigation.js';


//const initialRawContext = await AsyncStorage.getItem("appcontext") || false
const initialRawContext = false
//const initialContext = localStorage.setItem("appcontext", {})
let initialContext = {}
if (initialRawContext) {
  initialContext = JSON.parse(initialRawContext)
}

console.log("initial context", initialContext)
//export const AppContext = React.createContext(initialContext)

export const AppContext = React.createContext(initialContext)

export function useAppContext() {
  return useContext(AppContext)
}


const getData = async () => {
  try {
    const value = await AsyncStorage.getItem('loggedIn')
    if (value !== null) {
      // value previously stored
    }
  } catch (e) {
    // error reading value
  }
}

const storeData = async (value) => {
  try {
    await AsyncStorage.setItem('loggenIn', true)
  } catch (e) {
    // saving error
  }
}

export function AppContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [userDetails, setUserDetails] = useState()
  const [loading, setLoading] = useState(true)
  const [suppliers, setSuppliers] = useState([])
  const [restaurants, setRestaurants] = useState([])
  const [activeRestaurant, setActiveRestaurant] = useState(null)
  const [pastOrders, setPastOrders] = useState(initialContext.pastOrders || [])
  const [selectedSupplier, setSelectedSupplier] = useState(initialContext.selectedSupplier || null)
  const [currentOrders, setCurrentOrders] = useState(initialContext.currentOrders ? initialContext.currentOrders : {})

  useEffect(() => {
    async function storeState() {
      let rawAppcontext = await AsyncStorage.getItem("appcontext")
      if (!rawAppcontext) {
        return
      }
      let appcontext = JSON.parse(rawAppcontext)
      if (appcontext.selectedSupplier) {
        setSelectedSupplier(appcontext.selectedSupplier)
      }
      if (appcontext.currentOrders) {
        setCurrentOrders(appcontext.currentOrders)
      }
      if (appcontext.suppliers) {
        setSuppliers(appcontext.suppliers)
      }
      if (appcontext.pastOrders) {
        setPastOrders(appcontext.pastOrders)
      }
    }
    storeState()
  }, [])

  useEffect(() => {
    async function storeState() {
      AsyncStorage.setItem("appcontext", JSON.stringify(value))
    }
    storeState()
  }, [suppliers, pastOrders, selectedSupplier, currentOrders])


  function resetOrderForSupplier() {
    let newOrders = currentOrders
    delete newOrders[selectedSupplier.id]
    setCurrentOrders({ ...newOrders })
  }

  function updateOrders(localOrder) {
    //console.log("localOrder from AppContext", localOrder)
    let supplier = localOrder.supplier;
    let articleId = localOrder.id;
    let name = localOrder.name;
    let orderDetails = localOrder.orderDetails
    let newOrders = currentOrders
    let amount = orderDetails[0].amount

    if (amount === 0) {
      try {
        delete newOrders[supplier][articleId]
      } catch (e) {
        console.log(e)
      }
    } else {
      nestedObject(newOrders, [supplier, articleId], localOrder)
    }

    setCurrentOrders({ ...newOrders })
  }

  // useEffect(() => {
  //   async function storeState() {
  //     let rawAppcontext = await AsyncStorage.getItem("appcontext")
  //     if (!rawAppcontext) {
  //       return
  //     }
  //     // let appcontext = JSON.parse(rawAppcontext)
  //     // if (appcontext.selectedSupplier) {
  //     //   setSelectedSupplier(appcontext.selectedSupplier)
  //     // }
  //     // if (appcontext.currentOrders) {
  //     //   setCurrentOrders(appcontext.currentOrders)
  //     // }
  //     // if (appcontext.suppliers) {
  //     //   setSuppliers(appcontext.suppliers)
  //     // }
  //     // if (appcontext.pastOrders) {
  //     //   setPastOrders(appcontext.pastOrders)
  //     // }
  //   }
  //   storeState()
  // }, [])

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function addSupplier(newSupplier) {
    if (!suppliers.includes(newSupplier)) {
      setSuppliers([...suppliers, newSupplier])
    }
  }

  async function removeItemValue(key) {
    try {
      await AsyncStorage.removeItem(key);
      return true;
    }
    catch (exception) {
      return false;
    }
  }

  function updateArticle(article, supplierId) {
    let thisSupplierIndex = suppliers.findIndex((supplier) => supplier.id === supplierId)
    if (thisSupplierIndex >= 0) {
      let thisSupplier = suppliers[thisSupplierIndex]
      let articles = thisSupplier.articles
      console.log(article)
      console.log("this is articleid", article.id)
      let articleIndex = articles.findIndex((_article) => _article.id === article.id)
      console.log("articleIndex", articleIndex)
      if (articleIndex >= 0) {
        articles[articleIndex] = article
      } else {
        articles.push(article)
      }
      let newSuppliers = suppliers
      newSuppliers[thisSupplierIndex].articles = articles
      setSuppliers([...newSuppliers])
      updateSupplier(newSuppliers[thisSupplierIndex])
    }
  }

  function deleteArticle(article, supplierId) {
    let thisSupplierIndex = suppliers.findIndex((supplier) => supplier.id === supplierId)
    if (thisSupplierIndex >= 0) {
      let thisSupplier = suppliers[thisSupplierIndex]
      let articles = thisSupplier.articles
      let newArticles = articles.filter((_article) => _article.id !== article.id)
      let newSuppliers = suppliers
      newSuppliers[thisSupplierIndex].articles = newArticles
      setSuppliers([...newSuppliers])
      updateSupplier(newSuppliers[thisSupplierIndex])
    }
  }

  function addPastOrder(order) {
    let newPastOrders = pastOrders
    setPastOrders([order, ...newPastOrders])
  }

  function fetchPastOrders() {
    const user = auth.currentUser;
    console.log("hallo")
    if (!activeRestaurant) return;

    if (user !== null && user.uid !== undefined) {
      // The user object has basic properties such as display name, email, etc.

      // console.log("hellouser", user)

      var orderCollection = db.collection("orders");

      var query = orderCollection.where("restaurant", "==", activeRestaurant.id);

      query
        .get()
        .then((querySnapshot) => {
          var myOrders = []
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            var data = doc.data()
            myOrders.push(data)
          });

          console.log("TTT", myOrders)

          myOrders = myOrders.sort(function (a, b) {
            return b.timestamp - a.timestamp;
          });
          setPastOrders(myOrders)

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }

  console.log("suppliers from here ❗️", AppContext.Provider.value)


  async function logout() {
    //await AsyncStorage.clear()

    removeItemValue()
    await removeItemValue("loggedIn")
    await removeItemValue("appcontext")
    await removeItemValue("EXPO_CONSTANTS_INSTALLATION_ID")
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  function getCustomerIDForSupplier(supplierCode) {
    if (!userDetails) {
      return false
    }
    if (!userDetails.supplierConnection) {
      return false
    }
    let supplierConnection = userDetails.supplierConnection.find((obj => obj.supplier === supplierCode));
    if (supplierConnection) {
      let customerId = supplierConnection.customerId
      return customerId
    } else {
      return false
    }
  }

  function restaurantDetailsIsSet() {
    if (!userDetails) {
      console.log("123")
      return false
    }
    if (userDetails.restaurantName) {
      console.log("233")
      return true
    } else {
      console.log("344")
      return false
    }
  }

  function updateRestaurantDetails(restaurantDetails) {

    const user = auth.currentUser;
    if (user === null || user === undefined) {
      return
    }
    const uid = user.uid;

    var orderCollection = db.collection("users");

    var query = orderCollection.where("uid", "==", uid);
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          var userInfo = doc.data()
          var newUserInfo = {
            ...userInfo,
            ...restaurantDetails
          }

          db.collection("users").doc(doc.id).update(newUserInfo);

          setUserDetails(newUserInfo)
        })
      })
  }

  function updateSupplier(supplier) {
    console.log("update supplier called")
    const user = auth.currentUser;
    if (user === null || user === undefined) {
      return
    }

    db.collection("suppliers").doc(supplier.id).update(supplier);

  }

  function findUserInRestaurant(uid) {
    console.log("finuserinrestaurants")
    var orderCollection = db.collection("restaurants");
    var query = orderCollection.where("admins", "array-contains", uid);
    query
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          console.log('no restaurant documents found');

          RootNavigation.navigate('AddRestaurant');
        } else {
          let restaurants = []
          querySnapshot.forEach((doc) => {
            restaurants.push({ id: doc.id, ...doc.data() })
          });
          console.log('restaurants', restaurants)
          setRestaurants(restaurants)
          let defaultRestaurant = restaurants[0]
          setActiveRestaurant(defaultRestaurant)
          findSupplierForRestaurant(defaultRestaurant.id)
        }

      }).catch((error) => {
        console.log("Error loading restaurants: ", error);
      });
  }

  function findSupplierForRestaurant(restaurantId) {
    console.log("findSuppliers")
    var orderCollection = db.collection("suppliers");
    var query = orderCollection.where("restaurant", "==", restaurantId);
    query
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          //RootNavigation.navigate('AddSupplier');
          console.log('no suppliers documents found');
        }
        let suppliers = []
        querySnapshot.forEach((doc) => {
          suppliers.push({ id: doc.id, ...doc.data() })
        })
        setSuppliers(suppliers)
        //RootNavigation.navigate('SupplierSelection');

      }).catch((error) => {
        console.log("Error loading user: ", error);
      });
  }


  useEffect(async () => {
    const unsubscribe = auth.onAuthStateChanged(user => {

      console.log("here i am", user)
      setCurrentUser(user)
      setLoading(false)
      //fetchUser()

      async function checkLogginState() {

        if (!user) return
        if (!user.uid) return
        const value = await AsyncStorage.getItem('loggedIn')
        findUserInRestaurant(user.uid)
        // console.log("value", value)
        // if (value && value === "true") {

        // } else {
        //   await AsyncStorage.setItem('loggedIn', true)
        //   findUserInRestaurant(user.uid)
      }

      checkLogginState()

    })
    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    addSupplier,
    suppliers,
    activeRestaurant,
    pastOrders,
    currentOrders,
    //selectedArticles,
    selectedSupplier,

    setSelectedSupplier,
    resetOrderForSupplier,
    //getSelectedArticlesForSupplier,

    updateOrders,
    addPastOrder,
    fetchPastOrders,
    setActiveRestaurant,
    updateArticle,
    deleteArticle,
    findUserInRestaurant,
    updatePassword,
    getCustomerIDForSupplier,
    //updateSupplierCustomerId,
    restaurantDetailsIsSet,
    updateRestaurantDetails,
    //userDetails
  }

  return (
    <AppContext.Provider value={value}>
      {!loading && children}
    </AppContext.Provider>
  )
}
