import React from 'react'
import { TextInput } from 'react-native';
import styled from 'styled-components/native';
import colors from '../styles/Colors';
// const StandartInput = ({ value, onChange }) => {
//     return (
//         <Input
//             mx="3"
//             placeholder="Input"
//             w={["90%", "90%", "500"]}
//             borderRadius="10"
//             //borderColor="#2563EB"
//             borderWidth="2"
//             overflow="hidden"
//             value={value}
//             onChange={(e) => onChange(e.target.value)}
//         />
//     )
// }

// export default StandartInput

export const Input = styled.TextInput`
border: 1px solid gray;
height: 40px;
border-radius: 5px;
background-color: white;
padding: 10px;
`