import { useNavigation } from '@react-navigation/core'
import React, { useEffect } from 'react'
import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View, VirtualizedList, ScrollView } from 'react-native'
import { Center, Text, Box, Button, VStack } from 'native-base';
import Animation from 'lottie-react-native';
import animationData from '../lottie/check-animation';
import StandartHeading from '../components/StandartHeading';
import StandartButton from '../components/StandartButton';
import ButtonBottomWrapper from '../components/ButtonBottomWrapper';


const DATA = [];

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const OrderSuccess = (props) => {
  const navigation = useNavigation()
  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (<></>)
    });
  }, [props.navigation]);
  async function handleLogout() {
    //setError("")

    try {
      await logout()
      navigation.replace("Login")
    } catch {
      //setError("Failed to log out")
    }
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* <StandartHeading>Fertig</StandartHeading> */}

      <VStack>
        <Box w="400px" h="400px" justifyContent="center" alignItems="center" mx="auto">
          <Animation source={require('../lottie/check-animation')} autoPlay />
        </Box>
        <Text textAlign="center">Du erhälst eine Nachricht sobald der Lieferant deine Bestellung bestätigt hat</Text>

        <TouchableOpacity onPress={() => navigation.navigate("OrderHistoryStack", { screen: "OrderHistory" })} style={{ zIndex: 999, marginTop: 20, marginBottom: 200 }}>
          <Text style={{ color: "blue", textDecorationLine: "underline", textAlign: "center" }}>Bestelldetails ansehen</Text>
        </TouchableOpacity>
      </VStack>

      <ButtonBottomWrapper>
        <StandartButton action={() => navigation.navigate("SupplierSelection")} title={"Fertig"}></StandartButton>
      </ButtonBottomWrapper>

    </ScrollView >
  )
}

export default OrderSuccess

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-around",
    marginTop: StatusBar.currentHeight,
    minHeight: "100%",
    alignItems: "center"
  },
  item: {
    borderBottomColor: '#d3d3d3',
    borderBottomWidth: 2,
    height: 70,
    justifyContent: 'center',
    marginVertical: 8,
    padding: 20,
  },
  title: {
    fontSize: 32,
  },
});
