import React from 'react'
import { View, Text } from 'react-native'
import { Box } from 'native-base';

const ButtonBottomWrapper = ({ children }) => {
    return (
        <Box width="100%" display="flex" flexDirection="row" py={["20px", "20px", "40px"]} pr={[0, 0, "40px"]} justifyContent={["center", "center", "right"]} style={{ position: "absolute", bottom: 0 }}>
            {children}
        </Box>
    )
}

export default ButtonBottomWrapper
