import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { Input, HStack, Box } from 'native-base';
import { Entypo } from '@expo/vector-icons';
import { useAppContext } from '../contexts/AppContext';
export default function OrderItem(props) {
    const { updateOrders } = useAppContext()
    const { item } = props
    const [amount, setAmount] = useState(0)



    function updateAmount(newAmount) {
        setAmount(newAmount)
        let localOrder = {
            ...item,
            orderDetails: [
                { amount: newAmount, unit: "box" }
            ]
        }
        updateOrders(localOrder)

    }

    console.log("on render")
    return (
        <Box style={styles.item} >
            <HStack justifyContent={"space-around"}>
                <Text>{item.name}</Text>
                <HStack>
                    <TouchableOpacity onPress={() => { amount > 0 && updateAmount(amount - 1) }}>
                        <Entypo name="minus" size={24} color="grey" />
                    </TouchableOpacity>
                    <Input value={amount} w="40px" />
                    <TouchableOpacity onPress={() => { updateAmount((amount + 1)) }}>
                        <Entypo name="plus" size={24} color="grey" />
                    </TouchableOpacity>
                </HStack>
            </HStack>
        </Box>
    )
}

const styles = StyleSheet.create({
    item: {
        borderBottomColor: '#d3d3d3',
        borderBottomWidth: 2,
        height: 70,
        justifyContent: 'center',
        marginVertical: 8,
        padding: 20,
    },
    title: {
        fontSize: 32,
    },
});
