import { StyleSheet, StatusBar } from 'react-native'



export const containerStyle = {
    width: "100%",
    maxWidth: 900,
    marginHorizontal: "auto",
    flex: 1,
    marginTop: StatusBar.currentHeight,
}

const AppStyles = StyleSheet.create({
    container: containerStyle
})

export { AppStyles } 