import React, { useState, useEffect, useRef } from 'react'
import { useNavigation } from '@react-navigation/core'
import { HeaderBackButton } from '@react-navigation/elements'
import { View, Text, TouchableOpacity, SafeAreaView, StyleSheet, StatusBar, TextInput } from 'react-native'
import { Box, FlatList, ScrollView, TextArea, VStack, Select, CheckIcon } from 'native-base';
import { useAppContext } from '../contexts/AppContext';
import ButtonBottomWrapper from '../components/ButtonBottomWrapper';
import StandartButton from '../components/StandartButton';
import OrderItem from './OrderItem';
import StandartHeading from '../components/StandartHeading';
import { auth, db, functions } from "../firebase"
import { v4 as uuid } from 'uuid';
import { AppStyles } from '../styles/AppStyles'
import moment from 'moment';
import 'moment/locale/de'  // without this line it didn't work
moment.locale('de')
const DATA = [];

const getItem = (data, index) => ({
  id: Math.random().toString(12).substring(0),
  title: `Item ${index + 1}`,
});

const getItemCount = data => 150;

const Item = ({ title }) => (
  <View style={styles.item}>
    <Text style={styles.title}>{title}</Text>
  </View>
);

const CheckOrderScreen = (props) => {
  const navigation = useNavigation()
  const { currentOrders, selectedSupplier, resetOrderForSupplier, currentUser, suppliers, addPastOrder, activeRestaurant } = useAppContext()
  const [orderItems, setOrderItems] = useState([])
  const [annotation, setAnnotation] = useState("")
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState("Nächstmöglicher Termin")
  const orderAnnotationRef = useRef()

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (<HeaderBackButton onPress={() => { props.navigation.navigate('ProductSelection') }} />)
    });
  }, [props.navigation]);

  function addDataToDatabase() {
    setLoading(true)
    const user = auth.currentUser;
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      const uid = user.uid;
      // console.log("hellouser", user)
      let timestamp = Math.floor(Date.now() / 1000)
      console.log("🧙‍♂️", timestamp)
      const orderId = uuid()
      const newOrder = {
        id: orderId,
        user: uid,
        restaurant: activeRestaurant.id,
        supplier: { name: selectedSupplier.name, id: selectedSupplier.id },
        articles: currentOrders[selectedSupplier.id] ? currentOrders[selectedSupplier.id] : [],
        annotation,
        timestamp,
        date
      }
      db.collection("orders").add(newOrder)
        .then((docRef) => {

          sendOrder(docRef.id)
          console.log("Document written with ID: ", docRef.id);
          addPastOrder(newOrder)
          navigation.navigate("OrderSuccess")
          resetOrderForSupplier()
          setAnnotation("")
          setDate("Nächstmöglicher Termin")
          setLoading(false)
          //fetchPastOrders()

        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          setLoading(false)
        });
    }
  }

  function sendOrder(orderDocId) {
    //addDataToDatabase()
    if (!orderDocId) {
      return
    }
    console.log({ suppliers })
    let thisSupplier = suppliers.find((obj => obj.id === selectedSupplier.id))
    if (!thisSupplier) { return }
    const customerId = thisSupplier.customerId
    console.log({ customerId })
    let userDetails = {
      address: activeRestaurant.address,
      email: activeRestaurant.email,
      name: activeRestaurant.name
    }
    console.log("currentP", currentOrders[selectedSupplier.id])
    var addMessage = functions.httpsCallable('addMessage');
    addMessage({ articles: currentOrders[selectedSupplier.id], customerId, userDetails, annotation, orderDocId, date, supplierId: selectedSupplier.id }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })
  }

  function getOrderItems() {
    if (!selectedSupplier) {
      return
    }
    let thisOrder = currentOrders[selectedSupplier.id]
    console.log("mythisorder", thisOrder)
    if (!thisOrder) {
      return
    }
    var _orderItems = []

    for (let key of Object.keys(thisOrder)) {
      let orderItem = thisOrder[key]
      _orderItems.push({
        articleNr: key,
        supplier: selectedSupplier.id,
        ...orderItem,
        unit: orderItem.orderDetails[0].unit
      })
    }

    setOrderItems(_orderItems)
  }


  useEffect(() => {
    if (currentOrders && selectedSupplier) {
      getOrderItems()
    }

  }, [currentOrders, selectedSupplier])

  const subHeadingStyle = {
    fontSize: 15,
    marginLeft: 15,
    fontWeight: "bold",
    marginBottom: 5
  }
  return (
    <SafeAreaView style={AppStyles.container} >
      {/* <Box h="200px">
        <StandartHeading>Bestellung prüfen</StandartHeading>
      </Box> */}
      {/* <FlatList data={orderItems} renderItem={renderItem} keyExtractor={item => item.articleNr} /> */}
      {console.log(moment().add(1, "day"))}
      <ScrollView>
        <VStack bg="white" mb="20px" pt="15px" pb="10px">
          <Text style={subHeadingStyle}>Anmerkungen zur Bestellung</Text>
          <Box w="100%" >
            <TextArea style={{ borderColor: "gray" }} h={20} placeholder="Keine Anmerkung" m="10px" value={annotation} onChange={(e) => setAnnotation(e.currentTarget.value)} />
          </Box>
        </VStack>

        <VStack bg="white" mb="20px" pt="15px" pb="10px">
          <Text style={subHeadingStyle}>Lieferdatum</Text>
          <Select style={{ borderColor: "gray" }} selectedValue={date} minWidth="200" m="10px" accessibilityLabel="Datum der Lieferung" placeholder="Datum der Lieferung" _selectedItem={{
            bg: "teal.600",
            endIcon: <CheckIcon size="5" />
          }} mt={1} onValueChange={itemValue => setDate(itemValue)}>
            <Select.Item label="Nächstmöglicher Termin" value="Nächstmöglicher Termin" />
            {

              [...Array(14).keys()].map((i) => {
                let date = moment().add(i, 'days').format("dddd, DD.MM.YY")
                return <Select.Item label={date.toString()} value={date} key={'day-' + i} />
              })
            }
          </Select>
        </VStack>
        <VStack bg="white" mb="100px" pt="15px">
          <Text style={subHeadingStyle}>Artikel</Text>
          <FlatList
            data={orderItems}
            //‚initialNumToRender={30}
            renderItem={({ item }) => <OrderItem item={item} key={item.id} active={false} version={"check"} />}
            keyExtractor={item => item.id}
          //style={{ paddingBottom: 100 }}
          //getItemCount={getItemCount}
          //getItem={getItem}
          />
          {/* <TouchableOpacity onPress={() => navigation.navigate("CheckOrder")} style={{ width: 100, height: 50, backgroundColor: "green", position: "absolute" }}><Text>Weiter</Text></TouchableOpacity> */}
        </VStack>
      </ScrollView>
      <Box style={{ width: 300, height: 50, position: "absolute" }} bottom="40px" right={["50%", "50%", "40px"]} mx={["-150px", "-150px", "40px"]}>
        <StandartButton loading={loading} action={() => { !loading && addDataToDatabase() }} title={"Bestellung abschicken"}></StandartButton>
      </Box>
    </SafeAreaView >
  )
}

export default CheckOrderScreen