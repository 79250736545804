import { useNavigation } from '@react-navigation/core'
import React, { useState, useEffect, useRef } from 'react'
import { HeaderBackButton } from '@react-navigation/elements'
import { StyleSheet, TouchableOpacity, View, ScrollView, Dimensions } from 'react-native'
import { HStack, Box, FormControl, Button, Spacer, Text, Center } from 'native-base'
import { timestamp } from '../Utils';

import { useAppContext } from "../contexts/AppContext"
import StandartButton from '../components/StandartButton'
import ButtonBottomWrapper from '../components/ButtonBottomWrapper'
import StandartHeading from '../components/StandartHeading'
import { auth, db } from "../firebase"
import { nanoid } from 'nanoid'
import colors from '../styles/Colors'
import { Input } from '../components/StandartInput'

const AddProductScreen = (props) => {

  const navigation = useNavigation()
  const { updateArticle, deleteArticle, selectedSupplier } = useAppContext()

  const [articleName, setArticleName] = useState("");
  const [category, setCategory] = useState("")
  const [articleNr, setArticleNr] = useState("")
  const [availableUnits, setAvailableUnits] = useState(["Kiste", "Stück"])
  const [unit, setUnit] = useState("Kiste")
  const [errors, setErrors] = useState({});

  const { route } = props
  const params = route.params
  let windowWidth = Dimensions.get('window').width;

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (<HeaderBackButton onPress={() => { props.navigation.navigate('ProductSelection') }} />),
    });
  }, [props.navigation]);

  function addProductToDatabase() {
    // const user = auth.currentUser;
    // if (user !== null) {
    //   // The user object has basic properties such as display name, email, etc.
    //   const uid = user.uid;
    //   // console.log("hellouser", user)
    //   let timestamp = Math.floor(Date.now() / 1000)
    //   console.log("🧙‍♂️", timestamp)
    //   const orderId = uuid()
    //   const newOrder = {
    //     id: orderId,
    //     user: uid,
    //     restaurant: activeRestaurant.id,
    //     supplier: { name: selectedSupplier.name, id: selectedSupplier.id },
    //     articles: currentOrders[selectedSupplier.id] ? currentOrders[selectedSupplier.id] : [],
    //     annotation,
    //     timestamp
    //   }
    //   db.collection("orders").add(newOrder)
    //     .then((docRef) => {
    //       console.log("Document written with ID: ", docRef.id);
    //       addPastOrder(newOrder)
    //       navigation.navigate("OrderSuccess")
    //       resetOrderForSupplier()
    //       //fetchPastOrders()
    //     })
    //     .catch((error) => {
    //       console.error("Error adding document: ", error);
    //     });
    // }

    if (!validate()) {
      return
    }

    let existingArticle = {}
    if (params && params.article) {
      existingArticle = params.article
    }
    let newArticle = {
      ...existingArticle,
      articleNr: articleNr,
      name: articleName,
      tags: [category],
      unit: unit,
    }
    if (params && params.article && params.article.id !== undefined) {
      newArticle["id"] = params.article.id
    } else {
      newArticle["id"] = nanoid()
    }
    updateArticle(newArticle, selectedSupplier.id)
    //navigation.goBack()
    navigation.navigate("ProductSelection")
    //add product to article of selectedSupplier dann supplier updaten
    //update replace product on this index than supplier update
    //delete delete product on this index than supplier update
    //supplier update gibt es schon bei unit
  }

  function toggleTag(tag) {
    if (category === tag) {
      setCategory("")
    } else {
      setCategory(tag)
    }
  }

  useEffect(() => {

    //console.log(params.article)

    if (params && params.article) {
      let article = params.article
      setArticleName(article.name)
      setArticleNr(article.articleNr)
      if (article.unit) {
        setUnit(article.unit)
      }
      if (article.tags && article.tags.length > 0) {
        setCategory(article.tags[0])
      }

      if (article.availableUnits) {
        setAvailableUnits(article.availableUnits.split(", "))
      }
    }

  }, [params])

  // useEffect(() => {
  //   setIsMounted(true)
  //   return () => { setIsMounted(false) }; // cleanup toggles value, if unmounted
  // }, []);

  // function continueWithCustomerId(supplier) {
  //   //var newCustomerId = customerIdRef.current.value
  //   //console.log(newCustomerId)
  //   updateSupplierConnection(supplier, 111)
  //   navigation.navigate("AddSupplierUpload")
  //   //history.push("/products")
  // }

  const validate = () => {
    if (articleName === undefined || articleName === "") {
      setErrors({
        ...errors,
        name: 'Name is required'
      });
      return false;
    }
    return true;
  };

  return (
    <View style={styles.container}>
      {/* <TextInput
        onChangeText={setRestaurantName}
        value={restaurantName}
        placeholder="Lieferanten Name"
        keyboardType="numeric"
      /> */}
      {/* <StandartHeading>Wie heißt dein Restaurant?</StandartHeading> */}

      <Box w={["90%", "90%", "600px"]} mb="100px">
        <HStack my="7px">
          <FormControl isRequired w={"100%"} px="5px" isInvalid={'name' in errors}>
            <FormControl.Label _text={{
              bold: true
            }}>Name</FormControl.Label>
            <Input
              placeholder="Milch"
              // w={["90%", "90%", "500"]}
              //borderRadius="10"
              //borderColor="#2563EB"
              //borderWidth="2"
              overflow="hidden"
              value={articleName}
              onChange={(e) => setArticleName(e.target.value)}
            />
            {'name' in errors && <FormControl.ErrorMessage>Bitte gib einen Namen ein</FormControl.ErrorMessage>}
          </FormControl>
        </HStack>
        <HStack my="7px">
          <FormControl isRequired w={"100%"} px="5px">
            <FormControl.Label _text={{
              bold: true
            }}>Einheit</FormControl.Label>
            <HStack>
              {availableUnits.map((availableUnit) => {
                return (
                  <TouchableOpacity style={[styles.defaultUnitSelection, (unit === availableUnit && styles.activeUnitSelection)]} onPress={() => { setUnit(availableUnit) }}>
                    <Text style={[styles.defaultTextUnitSelection, (unit === availableUnit && styles.activeTextUnitSelection)]}>{availableUnit}</Text>
                  </TouchableOpacity>
                )
              })
              }
            </HStack>
          </FormControl>
        </HStack>
        <HStack>
          <FormControl isRequired w={"100%"} px="5px">
            <FormControl.Label _text={{
              bold: true
            }}>Kategorie</FormControl.Label>
            {selectedSupplier?.availableCategories &&
              <Box justifyContent={"center"} w="100%">
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} contentContainerStyle={{ height: 50 }}>
                  {
                    selectedSupplier.availableCategories.map((tag, index) => {
                      let activeCategory = category === tag
                      return (
                        <Button key={index} onPress={() => toggleTag(tag)} backgroundColor={activeCategory ? colors.primary : "white"} borderWidth={1} borderColor={colors.primary} borderRadius="full" m="5px" style={{ minWidth: 100, height: 30 }}>
                          <Text color={activeCategory ? "white" : "black"}>
                            {tag}
                          </Text>
                        </Button>
                      )
                    })
                  }
                </ScrollView>
              </Box>
            }
          </FormControl>
        </HStack>
        <HStack>
          <FormControl isRequired w={"100%"} px="5px">
            <FormControl.Label _text={{
              bold: true
            }}>ArtikelNr.</FormControl.Label>
            <Input placeholder="123456" onChange={(e) => setArticleNr(e.target.value)} value={articleNr} />
          </FormControl>
        </HStack>
        {
          params && params.article.id !== undefined &&
          <Center mt="70px">
            <TouchableOpacity onPress={() => { deleteArticle(params.article, selectedSupplier.id); props.navigation.navigate('ProductSelection'); }}><Text style={{ color: "red" }}>Produkt löschen</Text></TouchableOpacity>
          </Center>
        }
      </Box>
      <Spacer />
      <ButtonBottomWrapper>
        <StandartButton action={() => { addProductToDatabase() }} title={params && params.article.id !== undefined ? "Fertig" : "Produkt hinzufügen"}></StandartButton>
      </ButtonBottomWrapper>
    </View >
  )
}

export default AddProductScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
  defaultUnitSelection: {
    backgroundColor: "white",
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 20,
    margin: 5
  },
  activeUnitSelection: {
    backgroundColor: colors.primary,
  },
  defaultTextUnitSelection: { color: "black" },
  activeTextUnitSelection: { color: "white" },
})
