import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet } from 'react-native';
import { AppContextProvider } from "./contexts/AppContext"
import NavigationCentral from './NavigationCentral';
import { NativeBaseProvider } from 'native-base';
import 'react-native-gesture-handler';
import AppLoading from 'expo-app-loading';
import { useFonts, Inter_900Black } from '@expo-google-fonts/inter';


export default function App() {

  let [fontsLoaded] = useFonts({
    Inter_900Black,
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <AppContextProvider>
      <NativeBaseProvider>
        <NavigationCentral />
      </NativeBaseProvider>
    </AppContextProvider>)
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
