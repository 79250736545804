import { useNavigation } from '@react-navigation/core'
import { HeaderBackButton } from '@react-navigation/elements'
import { Box, VStack, HStack, Text, ScrollView } from 'native-base';
import React, { useEffect } from 'react'
import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View, VirtualizedList } from 'react-native'
import { useAppContext } from '../contexts/AppContext';
import { standartDate, unitDict } from '../Utils';
import StandartHeading from '../components/StandartHeading';
import { AppStyles } from '../styles/AppStyles'

const OrderHistoryDetail = (props) => {
  const { route } = props
  const navigation = useNavigation()
  const { pastOrders } = useAppContext()

  const { id } = route.params

  console.log("i have found this id", id)
  console.log("the pastorders i have found", pastOrders)
  let thisPastOrder = false
  if (id) {
    thisPastOrder = pastOrders.find((order) => order.id === id)
    console.log({ thisPastOrder })
  }

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (<HeaderBackButton onPress={() => { navigation.navigate('OrderHistory') }} />)
    });
  }, [navigation]);

  return (
    <ScrollView contentContainerStyle={AppStyles.container} style={{ paddingBottom: 100 }}>
      {!thisPastOrder ?
        <Text>Keine Order gefunden</Text>
        :
        <VStack bg="white">
          <Box h="100px">
            <StandartHeading>
              {thisPastOrder.supplier.name}
            </StandartHeading>
          </Box>
          <Text textAlign="center" fontSize="md">{standartDate(thisPastOrder.timestamp)}</Text>
          <Text textAlign="center" mt="15px">{thisPastOrder.annotation}</Text>
          {thisPastOrder.date && <Text textAlign="center" mt="15px">Gewünschtes Lieferdatum: {thisPastOrder.date}</Text>}
          <Box borderBottomWidth="1" borderColor="gray.300" w="100%" mt="20px"></Box>

          {Object.keys(thisPastOrder.articles).map((key, index) => {
            let article = thisPastOrder.articles[key]
            return (
              <Box key={"product_" + index} h="85px" alignItems="center" borderBottomWidth="1" borderColor="gray.300">
                <HStack justifyContent={"space-between"} alignItems={"center"} h="100%" w="95%">
                  <Text>{article.name}</Text>
                  <HStack>
                    <Text>{article.orderDetails[0].amount}x</Text><Text>{unitDict(article.orderDetails[0].unit)}</Text>
                  </HStack>
                </HStack>
              </Box>
            )
          })}


        </VStack>

      }
    </ScrollView>
  )
}

export default OrderHistoryDetail
