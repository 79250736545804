import { useNavigation } from '@react-navigation/core'
import React, { useState, useEffect, useRef } from 'react'
import { StyleSheet, ScrollView, Text, TouchableOpacity, View, TextInput, KeyboardAvoidingView, Dimensions } from 'react-native'
import { HeaderBackButton } from '@react-navigation/elements'
import { Box, FormControl, VStack, HStack } from 'native-base'
import { auth, db } from "../firebase"
import { useAppContext } from "../contexts/AppContext"
import StandartButton from '../components/StandartButton'
import ButtonBottomWrapper from '../components/ButtonBottomWrapper'
import StandartInput from '../components/StandartInput'
import StandartHeading from '../components/StandartHeading'
import SelectionOption from '../components/SelectionOption'
import { timestamp } from '../Utils'
import { Input } from '../components/StandartInput';
import { usefullHeight } from '../Utils'
import { containerStyle } from '../styles/AppStyles'

const AddSupplierScreen = (props) => {
  const navigation = useNavigation()
  const { activeRestaurant, addSupplier } = useAppContext()
  const [supplierName, setSupplierName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [orderProccess, setOrderProccess] = useState("orderAcceptance");
  const [errors, setErrors] = useState({});

  const customerIdRef = useRef(null);

  useEffect(() => {
    console.log({ activeRestaurant })
  }, [activeRestaurant])

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (<HeaderBackButton onPress={() => { props.navigation.navigate('SupplierSelection') }} />)
    });
  }, [props.navigation]);

  // function continueWithCustomerId(supplier) {
  //   //var newCustomerId = customerIdRef.current.value
  //   //console.log(newCustomerId)
  //   //updateSupplierConnection(supplier, 111)

  //   //history.push("/products")
  // }

  function validate() {
    if (supplierName !== "") {
      return true
    } else {
      setErrors({
        supplierName: 'supplierName is required'
      });
      return false
    }
  }


  return (
    <KeyboardAvoidingView enabled={false} style={{
      minHeight: usefullHeight, alignItems: "center"
    }} contentContainerStyle={{ minHeight: "100%" }}>
      <Box w={["100%", "100%", "600px"]} mt={usefullHeight * 0.1}>
        <HStack my="7px" px="5%">
          <FormControl isRequired w={"100%"} px="5px" isInvalid={'supplierName' in errors}>
            <FormControl.Label _text={{
              bold: true
            }}>Wie heißt dein Lieferant?</FormControl.Label>
            <Input
              placeholder="Mein Lieferant"
              // w={["90%", "90%", "500"]}
              //borderRadius="10"
              //borderColor="#2563EB"
              //borderWidth="2"
              overflow="hidden"
              value={supplierName}
              onSubmitEditing={() => customerIdRef.current.focus()}
              returnKeyType="next"
              onChange={(e) => setSupplierName(e.target.value)}

            />
            {'supplierName' in errors && <FormControl.ErrorMessage>Bitte gib den Namen des Lieferanten ein</FormControl.ErrorMessage>}
          </FormControl>
        </HStack>
        <HStack my="7px" px="5%">
          <FormControl w={"100%"} px="5px">
            <FormControl.Label _text={{
              bold: true
            }}>Kundennummer (falls vorhanden)</FormControl.Label>
            <Input
              placeholder="123456"
              // w={["90%", "90%", "500"]}
              //borderRadius="10"
              //borderColor="#2563EB"
              //borderWidth="2"
              overflow="hidden"
              ref={customerIdRef}
              returnKeyType="done"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}

            />
          </FormControl>
        </HStack>
        <HStack my="7px" mt="30px">
          <FormControl isRequired w={"100%"} px="5px">
            <FormControl.Label px="5%" _text={{
              bold: true
            }}>Wohin sendest du deine Bestellung?</FormControl.Label>
            <Box px="5%">
              <VStack>
                <SelectionOption title={"Bestellannahme"} active={orderProccess == "orderAcceptance"} action={() => setOrderProccess("orderAcceptance")} />
                <SelectionOption title={"Außendienstmitarbeiter"} active={orderProccess == "salesRepresentative"} action={() => setOrderProccess("salesRepresentative")} />
              </VStack>
            </Box>
          </FormControl>
        </HStack>
      </Box>
      <ButtonBottomWrapper>
        <StandartButton action={() => { validate() && navigation.navigate("AddSupplierDetails", { supplierName, customerId, orderProccess }) }}></StandartButton>
      </ButtonBottomWrapper>
    </KeyboardAvoidingView >
  )
}

export default AddSupplierScreen

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: "100%",
    height: "100%"
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
  standartFormText: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 16
  }
})
