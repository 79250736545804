import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/core'
import { HeaderBackButton } from '@react-navigation/elements'
import { View, Text, SafeAreaView, StyleSheet, TouchableOpacity } from 'react-native'
import { getAuth, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { initializeApp, getApp } from 'firebase/app';
import StandartHeading from '../components/StandartHeading';
import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { Button, Box, Center, KeyboardAvoidingView } from 'native-base';

const app = getApp();
const auth = getAuth();
auth.setPersistence('local')

const CELL_COUNT = 6;

const PhoneCodeScreen = (props) => {
    const navigation = useNavigation()
    const [value, setValue] = useState('');
    const [message, showMessage] = useState();
    const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
    const [inputFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
        value,
        setValue,
    });
    const { verificationId } = props.route.params

    useEffect(() => {
        props.navigation.setOptions({
            headerLeft: () => (<HeaderBackButton onPress={() => { props.navigation.navigate('SignUpPhone') }} />),

            //     //: { elevation: 0, shadowColor: 'transparent', headerShadowVisible: false }

        }, [props.navigation]
        );
    })

    const verifyCode = async () => {
        try {
            const credential = PhoneAuthProvider.credential(
                verificationId,
                value
            );
            let signInResult = await signInWithCredential(auth, credential);
            console.log({ signInResult })
            showMessage({ text: 'Phone authentication successful 👍' });
        } catch (err) {
            showMessage({ text: `Error: ${err.message}`, color: 'red' });
        }
    }

    useEffect(() => {
        if (value.length === CELL_COUNT) {
            verifyCode()
        }
    }, [value])

    return (
        <SafeAreaView style={styles.root}>

            <Box style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <StandartHeading>Bestätigung</StandartHeading>
                <KeyboardAvoidingView>
                    <CodeField
                        ref={ref}
                        {...inputFieldProps}
                        value={value}
                        onChangeText={setValue}
                        cellCount={CELL_COUNT}
                        rootStyle={styles.codeFieldRoot}
                        keyboardType="number-pad"
                        textContentType="oneTimeCode"
                        // style={{ outlineStyle: 'none' }}
                        renderCell={({ index, symbol, isFocused }) => (
                            <View
                                // Make sure that you pass onLayout={getCellOnLayoutHandler(index)} prop to root component of "Cell"
                                onLayout={getCellOnLayoutHandler(index)}
                                key={index}
                                style={[styles.cellRoot, isFocused && styles.focusCell]}>
                                <Text style={styles.cellText}>
                                    {symbol || (isFocused ? <Cursor /> : null)}
                                </Text>
                            </View>
                        )}
                    />
                    {message &&
                        <Text>
                            {message.text}
                        </Text>

                    }
                </KeyboardAvoidingView>
            </Box>

            {/* <TouchableOpacity onPress={() => navigation.navigate("AddSupplier")}>
                <Text>
                    Weiter
                </Text>
            </TouchableOpacity> */}
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    root: { padding: 20, minHeight: "100%" },
    title: { textAlign: 'center', fontSize: 30 },
    codeFieldRoot: {
        marginTop: 20,
        width: 320,
        marginLeft: 'auto',
        marginRight: 'auto',
        //outlineStyle: 'none',
        //border: 'none',
        borderWidth: 0
    },
    cellRoot: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        //outlineStyle: 'none',
        borderWidth: 0
    },
    cellText: {
        color: '#000',
        fontSize: 36,
        textAlign: 'center',
        //outlineStyle: 'none'
    },
    focusCell: {
        borderWidth: 0,
        borderBottomColor: '#007AFF',
        borderBottomWidth: 2,
        //outlineStyle: 'none'
    },
});
export default PhoneCodeScreen
