import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Button, Box, Spinner } from 'native-base'
import colors from '../styles/Colors'
const StandartButton = (props) => {
    const { title, action, colorScheme = "blue", disabled = false, loading = false } = props
    let defaultTitle = "Weiter"

    return (
        <Box>
            <Button onPress={() => action()} backgroundColor={colors.primary} rounded="md" style={{ width: 300, height: 50 }} isDisabled={disabled}>
                {loading ?
                    <Spinner color="white" /> :
                    title ? title : defaultTitle
                }
            </Button>
        </Box>
    )
}

export default StandartButton

const styles = StyleSheet.create({})
