import React from 'react'
import { TouchableOpacity } from 'react-native';
import { Box, IconButton, CloseIcon, Image, VStack, HStack, Text, Pressable, Center } from 'native-base'
import sheetsIcon from '../assets/sheets.png';
import pdfIcon from '../assets/pdf.png';
const UploadFileElement = (props) => {
    const file = props.file
    let displayImage
    console.log({ file })
    displayImage = <Image source={{
        uri: file
    }} w="150px" h="100px" resizeMethod="contain" borderRadius="md" />
    // if (true || file.mimeType === "image/jpeg" || file.mimeType === "image/png") {
    //     displayImage = <Image source={{
    //         uri: file.uri
    //     }} size="xl" borderRadius="md" />
    // } else if (file.mimeType === "application/pdf") {
    //     displayImage = <Image source={pdfIcon} size="xl" borderRadius="md" />
    // } else {
    //     displayImage = <Image source={sheetsIcon} size="xl" borderRadius="md" />
    // }
    return (
        <Box borderRadius="md" bg="#d9effc" w="60%" mb="6px" p="4">
            <HStack>
                {displayImage}
                <Center>
                    {/* <Text ml={5} fontSize="xl">{file.name}</Text> */}
                </Center>
            </HStack>
            <Box style={{ position: "absolute", top: 5, right: 5, zIndex: 999 }}>
                <IconButton variant="unstyled" icon={<CloseIcon size="3" color="coolGray.600" />} onPress={() => props.onDelete()} />
            </Box>
        </Box>
    )
}

export default UploadFileElement
