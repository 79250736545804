import React, { useEffect } from 'react'
import { SafeAreaView, StatusBar, StyleSheet, Text, TouchableOpacity, View, FlatList } from 'react-native'
import { Box, VStack, Divider, HStack, Spacer, Button } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { useAppContext } from '../contexts/AppContext';
import { HeaderBackButton } from '@react-navigation/elements'
import moment from "moment";
import StandartHeading from '../components/StandartHeading';
import { standartDate } from '../Utils';
import DrawerMenuIcon from '../components/DrawerMenuIcon'


const Settings = (props) => {
    const navigation = useNavigation()
    const { logout } = useAppContext()

    useEffect(() => {
        props.navigation.setOptions({
            headerLeft: () => (
                <DrawerMenuIcon navigation={props.navigation} />
            )
        });
    }, [props.navigation]);

    return (
        <SafeAreaView style={styles.container}>
            <View style={{ position: "absolute", bottom: 30, left: 0, width: "100%" }}>
                <TouchableOpacity style={{ padding: 50 }} onPress={() => logout()}><Text style={{ textAlign: "center" }}>Abmelden</Text></TouchableOpacity>
            </View>
        </SafeAreaView>
    )
}

export default Settings

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: StatusBar.currentHeight,
    },
    item: {
        borderBottomColor: '#d3d3d3',
        borderBottomWidth: 2,
        height: 70,
        justifyContent: 'center',
        marginVertical: 8,
        padding: 20,
    },
    title: {
        fontSize: 32,
    },
});
