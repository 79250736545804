import { useNavigation } from '@react-navigation/core'
import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, TextInput } from 'react-native'
import StandartHeading from '../components/StandartHeading'
import { CommonActions } from '@react-navigation/native';
import StandartButton from '../components/StandartButton';
import ButtonBottomWrapper from '../components/ButtonBottomWrapper';
import { db, functions } from "../firebase"
import { Button, Box } from 'native-base'

const SupplierOrderConfirmationScreen = (props) => {
  const navigation = useNavigation()

  const { route } = props


  useEffect(() => {

    async function confirmOrderProcess() {

      const { orderDocId } = route.params

      if (!orderDocId) {
        return
      }

      var confirmOrder = functions.httpsCallable('confirmOrder');
      confirmOrder({ orderDocId }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })

    }

    confirmOrderProcess()

  }, [route])

  // useEffect(() => {
  //   navigation.dispatch(
  //     CommonActions.reset({
  //       index: 1,
  //       routes: [
  //         { name: 'AddSupplierWait' }
  //       ],
  //     })
  //   );
  // }, [])

  return (
    <View style={styles.container}>
      <StandartHeading>Vielen Dank! {"\n"}Lieferung bestätigt</StandartHeading>
    </View >
  )
}

export default SupplierOrderConfirmationScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
})
