import { useNavigation } from '@react-navigation/core'
import * as ImagePicker from 'expo-image-picker'
import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View, TextInput, Platform, Image, ActivityIndicator } from 'react-native'
import { useAppContext } from "../contexts/AppContext"
import StandartButton from '../components/StandartButton'
import ButtonBottomWrapper from '../components/ButtonBottomWrapper'
import StandartInput from '../components/StandartInput'
import StandartHeading from '../components/StandartHeading'
import app, { storage } from '../firebase'
import UploadFileElement from './UploadFileElement'
import { v4 as uuidv4 } from 'uuid';
import { timestamp } from '../Utils'
import { Box, VStack, Button, Text, ScrollView } from 'native-base'
import { auth, db, functions } from "../firebase"
import colors from '../styles/Colors'


const AddSupplierUploadScreen = (props) => {
  const navigation = useNavigation()
  const { updateSupplierCustomerId, activeRestaurant, addSupplier } = useAppContext()
  const [supplierName, setSupplierName] = useState(false);
  const [supplierEmail, setSupplierEmail] = useState(false);
  const [filesForUpload, setFilesForUpload] = useState([]);
  const [loading, isLoading] = useState(false);
  const [successfullUploadedFiles, setSuccessfullUploadedFiles] = useState([])
  const { route } = props
  function continueWithCustomerId(supplier) {
    //var newCustomerId = customerIdRef.current.value
    //console.log(newCustomerId)
    updateSupplierCustomerId(supplier, 111)
    //history.push("/products")
  }

  let uploadImage = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    let newFileId = uuidv4() + ".jpg"
    var ref = app.storage().ref().child(newFileId);
    let newFiles = successfullUploadedFiles
    newFiles.push(newFileId)
    setSuccessfullUploadedFiles([...newFiles])
    return ref.put(blob);
  }

  async function pickDocument() {

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      quality: 1,

    });
    console.log({ result })
    setFilesForUpload([...filesForUpload, result.uri])
    //alert(result.uri);

    //uploadImage(result.uri)

    console.log(result);
  }

  async function addNewSupplier() {
    const supplierInfo = route.params.supplierInfo
    console.log("supplierInfo", supplierInfo)
    if (supplierInfo.name === "") return
    if (!activeRestaurant) return;
    let newSupplierId = uuidv4()
    let time = timestamp()

    if (filesForUpload.length > 0) {
      await uploadPreparedFiles()
    }

    const newSupplier = {
      status: "pending",
      restaurant: activeRestaurant.id,
      ...supplierInfo,
      updatedTime: time,
      addedTime: time,
      files: successfullUploadedFiles
    }

    console.log({ newSupplier })

    db.collection("suppliers").add(newSupplier)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        addSupplier(newSupplier)
        var kickStartProcess = functions.httpsCallable('digitalizeSupplierProcess');

        kickStartProcess({ restaurantId: activeRestaurant.id }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })

        navigation.navigate("AddSupplierWait")
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  // function finalize() {
  //   var kickStartProcess = functions.httpsCallable('digitalizeSupplierProcess');

  //   kickStartProcess({ restaurantId: activeRestaurant.id }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })

  //   navigation.navigate("AddSupplierWait")
  // }

  async function uploadPreparedFiles() {
    isLoading(true)

    for (let file of filesForUpload) {
      await uploadImage(file)
    }

    // db.collection("suppliers").doc(route.params.supplierDocId).update({ 'files': successfullUploadedFiles }).then((result) => console.log(result)).catch((err) => console.log("err", err))
    // console.log("qqweqwqew", successfullUploadedFiles)

    isLoading(false)

    //finalize()
  }

  function onDelete(targetIndex) {
    console.log(filesForUpload)
    let newFilesForUpload = filesForUpload.filter((_, index) => index !== targetIndex)
    console.log(newFilesForUpload)
    setFilesForUpload([...newFilesForUpload])
  }

  return (
    <View style={styles.container}>
      {loading && <ActivityIndicator size="large" />}
      <StandartHeading>Lade deine Bestelliste hoch</StandartHeading>

      <Button
        backgroundColor={colors.primary} rounded="md" mt="200px" style={{ width: 300, height: 50 }}
        title="Select Document"
        onPress={() => pickDocument()}
      >
        {filesForUpload.length > 0 ? "Weitere Bestelliste hinzufügen" : "Bestelliste hinzufügen"}
      </Button>
      <ScrollView mt="20px" mb="70px" w="100%" contentContainerStyle={{ alignItems: "center" }} >

        {filesForUpload.map((file, index) => {
          return (
            <UploadFileElement file={file} key={"preview-" + index} onDelete={() => {
              onDelete(index)
            }
            } />)
        })}
        {filesForUpload.length === 0 && <Text textAlign="center">
          {/* Lade ein Foto deiner Bestellliste hoch. {"\n"} */}
          {/* Wenn du deine Bestellliste als {"\n"} PDF oder Excel vorliegen hast,{"\n"} kannst du auch diese hochladen. */}
        </Text>}
      </ScrollView>
      {
        Platform.OS !== "web" && <TouchableOpacity>
          <View style={{ width: 100, height: 100, borderWidth: 2 }}>
            <Text>
              Bestelliste hinzufügen
            </Text>
          </View>
        </TouchableOpacity>
      }


      <ButtonBottomWrapper >
        <VStack>
          {filesForUpload.length === 0 && <Box w="100%" alignItems="center" justifyContent="center" fontFamily="sans-serif">
            <TouchableOpacity onPress={() => { addNewSupplier() }} style={{ paddingBottom: 20 }}>
              <Text>Diesen Schritt überspringen</Text>
            </TouchableOpacity></Box>}

          <StandartButton action={() => { addNewSupplier() }} title="Fertig" disabled={filesForUpload.length === 0}></StandartButton>

        </VStack>
      </ButtonBottomWrapper>
    </View >
  )
}

export default AddSupplierUploadScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
})
