import { useNavigation } from '@react-navigation/core'
import { HeaderBackButton } from '@react-navigation/elements'
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout'
import React, { useState, useEffect, useRef } from 'react'
import { SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View, VirtualizedList, TextInput, SectionList, Dimensions, PixelRatio } from 'react-native'
import OldOrderItem from './OldOrderItem';
import OrderItem from './OrderItem';
import ButtonBottomWrapper from '../components/ButtonBottomWrapper';
import StandartButton from '../components/StandartButton';
import StandartHeading from '../components/StandartHeading';
import { Box, Button, FlatList, Input, SmallCloseIcon } from 'native-base';
import { useAppContext } from '../contexts/AppContext';
import { AppStyles } from '../styles/AppStyles'
import { Entypo } from '@expo/vector-icons';
import colors from '../styles/Colors';
import Tag from '../components/Tag';

let tagRenderDict = []

const ProductSelectionScreen = (props) => {
  const navigation = useNavigation()
  const [tagSelection, setTagSelection] = useState("")
  const [userSelectedTag, setUserSelectedTag] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [existingCategories, setExistingCategories] = useState([])
  const { currentOrders, selectedSupplier, suppliers } = useAppContext()
  const [searchString, setSearchString] = useState("")
  const [DATA, setDATA] = useState([])
  const [sortedData, setSortedData] = useState([])
  const [activeIndex, setActiveIndex] = useState(1)
  const refSectionList = useRef();
  const tagListRef = useRef()


  let activeCategory = ""
  let windowWidth = Dimensions.get('window').width;


  const getItemLayout = sectionListGetItemLayout({
    // The height of the row with rowData at the given sectionIndex and rowIndex
    getItemHeight: (rowData, sectionIndex, rowIndex) => sectionIndex === 0 ? 80 : 80,

    // These three properties are optional
    //getSeparatorHeight: () => 1 / PixelRatio.get(), // The height of your separators
    getSeparatorHeight: () => 0, // The height of your separators

    getSectionHeaderHeight: () => 40, // The height of your section headers
    getSectionFooterHeight: () => 0, // The height of your section footers
  })

  // useEffect(() => {


  //   if (tagSelection === "" && searchString === "") {
  //     setFilteredData(DATA)
  //   } else {
  //     var selectionResult = []

  //     for (let dataItem of DATA) {
  //       if (dataItem.tags && dataItem.tags.includes(tagSelection)) {
  //         selectionResult.push(dataItem)
  //       }

  //       setFilteredData(selectionResult)
  //     }

  //   }

  //   if (tagSelection !== "" && searchString !== "") {
  //     setSearchString("")
  //   }



  // }, [tagSelection])


  useEffect(() => {
    if (selectedSupplier) {
      props.navigation.setOptions({
        title: selectedSupplier.name,
        headerLeft: () => (<HeaderBackButton onPress={() => { props.navigation.navigate('SupplierSelection') }} />),
        headerRight: () => (<TouchableOpacity onPress={() => navigation.navigate("EditProduct")}><View style={{ backgroundColor: colors.primary, width: 30, height: 30, borderRadius: 15, justifyContent: "center", alignItems: "center", marginRight: 10 }}><Text><Entypo name="plus" size={18} color="white" /></Text></View></TouchableOpacity>)
        // headerLeft: search
      });
    }


  }, [props.navigation, selectedSupplier]);


  function sortProductsByCategories(rawArticles) {

    let sortedArticles = {}
    let _existingCategories = []
    for (let article of rawArticles) {
      let thisCategory = article.tags[0]
      if (sortedArticles[thisCategory]) {
        sortedArticles[thisCategory].push(article)
      } else {
        sortedArticles[thisCategory] = [article]
      }
    }

    let availableCategories = selectedSupplier.availableCategories
    let sortedArticlesWithTitles = []
    let correctOrder = []
    for (let key of Object.keys(sortedArticles)) {
      let insertIndex = availableCategories.indexOf(key)
      let item = { title: key, data: sortedArticles[key] }
      if (item.title == "" || item.title == " ") {
        item.title = "Ohne Kategorie"
      }
      if (insertIndex >= 0) {
        sortedArticlesWithTitles[insertIndex] = item
      } else {
        // if (item.title = "") {
        //   item.title = "Ohne Kategorie"
        // }
        sortedArticlesWithTitles.push(item)
      }
    }

    for (let category of sortedArticlesWithTitles) {
      if (!!category) {
        _existingCategories.push(category.title)
        correctOrder.push(category)
      }
    }

    setExistingCategories(_existingCategories)
    return correctOrder
  }

  useEffect(() => {
    if (!selectedSupplier || selectedSupplier.articles.length === 0 || suppliers.length === 0) return
    let thisSupplier = suppliers.find((supplier) => supplier.id === selectedSupplier.id)
    if (thisSupplier) {

      let articles = thisSupplier.articles

      setDATA([...articles])

      setFilteredData([...articles])

      let _sortedData = sortProductsByCategories(articles)

      setSortedData(_sortedData)
    }
  }, [suppliers])


  useEffect(() => {
    setFilteredData(DATA)
  }, [DATA])

  function search() {

    var matches = []
    DATA.find(element => {
      if (element.name.toLowerCase().includes(searchString)) {
        matches.push(element)
      }
    });

    setFilteredData(matches)

  }

  useEffect(() => {

    if (searchString === "") {
      setFilteredData(DATA)
    } else {

      const delayDebounceFn = setTimeout(() => {
        search()
      }, 300)
      // if (searchString !== "" && tagSelection !== "") {
      //   setTagSelection("")
      // }
      return () => clearTimeout(delayDebounceFn)
    }


  }, [searchString])

  function selectTag(tag, index) {

    setUserSelectedTag(tag)
    setTagSelection(tag)
    setActiveIndex(index)
    try {
      refSectionList.current.scrollToLocation(
        {
          sectionIndex: index,
          itemIndex: 0
        })
    } catch (e) {

      return (e)
    }


  }

  function Search() {
    return (
      <TextInput placeholder='Suche' style={{}} mx="10px" mt="60px" value={searchString} onChange={(event) => setSearchString(event.target.value.toLowerCase())} InputRightElement={false && <TouchableOpacity onPress={() => { console.log("abc"); setSearchString("") }} style={{ zIndex: 999 }}><SmallCloseIcon color={"grey"} size={4} pr="10px" /></TouchableOpacity>} />
    )
  }

  function trackActiveCategory(data) {

    try {
      let thisCategory = data.viewableItems[0].section.title
      if (thisCategory !== activeCategory) {
        activeCategory = thisCategory
        if (activeCategory === userSelectedTag) {
          setUserSelectedTag("")
          console.log("arrived")
        }
        if (userSelectedTag === "") {
          setTagSelection(activeCategory)
          for (let tagInfo of tagRenderDict) {
            if (tagInfo.tag === activeCategory) {
              if (windowWidth > 900) {
                windowWidth = 900
              }
              tagListRef.current.scrollTo({ x: tagInfo.x - (windowWidth / 2) + tagInfo.width / 2, y: 0, animated: true })
            }
          }
        }
      }

    }


    catch (e) {
      console.log(e)
      return
    }

  }

  function isEnabled() {

    try {
      if (Object.keys(currentOrders[selectedSupplier.id]).length > 0) {
        return true
      } else {
        return false
      }

    } catch (e) {
      return false
    }
  }




  return (
    <>
      <SafeAreaView style={AppStyles.container} >
        <Box w="100%" backgroundColor="white" style={{ alignItems: "center", zIndex: 999 }}>
          <Box style={{ width: "95%", height: 40, marginHorizontal: "auto", marginVertical: 10, }}>
            <TextInput placeholder='Suche' style={{ position: "absolute", width: "100%", height: "100%", padding: 20, borderRadius: 20, backgroundColor: "#f2f2f2", zIndex: 999 }} value={(searchString).toString()} onChange={(event) => setSearchString(event.target.value.toLowerCase())} />
            {searchString !== "" && <TouchableOpacity onPress={() => { setSearchString("") }} style={{ zIndex: 999, position: "absolute", right: 3, top: 7 }}><SmallCloseIcon color={"grey"} size={6} pr="10px" /></TouchableOpacity>}
          </Box>
        </Box>
        {searchString === "" && selectedSupplier?.availableCategories &&
          <Box backgroundColor="white" justifyContent={"center"} w="100%">
            <ScrollView ref={tagListRef} horizontal={true} showsHorizontalScrollIndicator={false} contentContainerStyle={{ height: 50, backgroundColor: "white" }}>
              {
                existingCategories.map((tag, index) => {
                  return (
                    // <Button key={index} onPress={} colorScheme={tagSelection === tag ? "blue" : "light.50"} borderWidth={1} borderColor="info.600" borderRadius="full" m="5px" style={{ minWidth: 100, height: 30 }}>
                    //   {tag}
                    // </Button>
                    <View onLayout={(event) => {
                      const layout = event.nativeEvent.layout
                      tagRenderDict.push({ x: layout.x, width: layout.width, tag })

                    }}
                      key={index}>
                      <Tag action={() => selectTag(tag, index)} active={tagSelection === tag} title={tag} />
                    </View>
                  )
                })
              }
            </ScrollView>
          </Box>
        }


        {searchString !== "" ?

          <FlatList
            data={filteredData}
            //‚initialNumToRender={30}
            renderItem={({ item }) => <OrderItem item={item} key={item.id} active={true} />}
            keyExtractor={item => item.id}
            //getItemCount={getItemCount}
            //getItem={getItem}
            initialNumToRender={40}
            style={{ paddingBottom: 100 }}
          />
          :
          <SectionList
            sections={sortedData}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item }) => {
              return <OrderItem item={item} key={item.id} active={true} />

              //return <View style={{ height: 80 }}><Text>ListItem</Text></View>
            }}
            renderSectionHeader={({ section: { title } }) => (
              <Text style={{ marginLeft: 20, color: "gray", height: 40, display: "flex", flexDirection: "column", justifyContent: "center" }}>{title}</Text>
            )}
            ref={refSectionList}
            style={{ paddingBottom: 100, backgroundColor: "#e3e3e3" }}
            onViewableItemsChanged={(data) => trackActiveCategory(data)}
            iewabilityConfig={{
              itemVisiblePercentThreshold: 50 //means if 50% of the item is visible
            }}
            // getItemLayout={(data, index) => (
            //   { length: 80, offset: 80 * index, index }
            // )}
            getItemLayout={getItemLayout}

          //initialNumToRender={30 * activeIndex}
          // onScrollToIndexFailed={info => {
          //   const wait = new Promise(resolve => setTimeout(resolve, 500));
          //   wait.then(() => {
          //     refSectionList.current.scrollToLocation(
          //       {
          //         sectionIndex: info.index,
          //         itemIndex: 0
          //       })
          //   });
          // }}
          />


        }




      </SafeAreaView>

      {isEnabled() && <Box style={{ width: 300, height: 50, position: "absolute" }} bottom="40px" right={["50%", "50%", "40px"]} mx={["-150px", "-150px", "40px"]}>
        <StandartButton action={() => navigation.navigate("CheckOrder")} disabled={!isEnabled()}></StandartButton>
      </Box>}
    </>

  )
}

export default ProductSelectionScreen

const styles = StyleSheet.create({
  item: {
    borderBottomColor: '#d3d3d3',
    borderBottomWidth: 2,
    height: 70,
    justifyContent: 'center',
    marginVertical: 8,
    padding: 20,
  },
  title: {
    fontSize: 32,
  },
});
