import { useNavigation } from '@react-navigation/core'
import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useAppContext } from "../contexts/AppContext"

const HomeScreen = () => {
  const navigation = useNavigation()
  const { currentUser, logout } = useAppContext()

  async function handleLogout() {
    //setError("")

    try {
      await logout()
      navigation.navigate("Login")
    } catch {
      //setError("Failed to log out")
    }
  }

  return (
    <View style={styles.container}>
      <Text>Email: {currentUser?.email}</Text>
      <TouchableOpacity
        style={styles.button}
        onPress={() => handleLogout()}
      >
        <Text style={styles.buttonText}>Sign out</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.button}
        onPress={() => navigation.navigate("ProductSelection")}
      >
        <Text>ProductSelection</Text>
      </TouchableOpacity>
    </View>
  )
}

export default HomeScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    backgroundColor: '#0782F9',
    width: '60%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
})
