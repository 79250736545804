import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Button, Text } from 'native-base'
import colors from '../styles/Colors'
const SelectionOption = ({ active, title, action }) => {
    return (
        <Button onPress={action} backgroundColor={active ? colors.primary : "white"} borderWidth={2} borderColor={colors.primary} rounded="md" mt="8px" style={{ minWidth: 100, height: 50 }}>
            <Text color={active ? "white" : "black"}>{title}</Text>
        </Button>
        //     <TouchableOpacity onPress={action} style={{ height: 25, flexDirection: "row", justifyContent: "center", marginHorizontal: 10, marginVertical: 10, marginBottom: 30 }}>
        //         <Text color={"black"} style={{ textAlign: "center", borderBottomWidth: active ? 3 : "0", borderBottomColor: colors.primary }}>{title}</Text>
        //     </TouchableOpacity>
    )
}

export default SelectionOption
